import React, { useContext } from "react";
import "./CommonMedFusion.css";
import { arrow } from "../../utils/images/index";
import { MyContext } from "../../context/ContextApi";
import { getSourceData } from "../../api/GetActions";
import { queryMedFusionData } from "../../api/Postaction";

function SearchInputMedFusion({ inputValue, placeholder, setInputData }) {
  const {
    fileNameExtract,
    setQueryResponse,
    setQuery,
    setSourceCallIn,
    setSourceDataCallIn,
    setGenerating,
  } = useContext(MyContext);

  const user = sessionStorage.getItem("firstname");

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleQueryClick = async () => {
    setGenerating(true);
    let body = {
      user_name: user,
      filename: fileNameExtract,
      question: inputValue,
    };
    setQuery((prev) => [...prev, inputValue]);
    try {
      let res = await queryMedFusionData(body);
      setQueryResponse((prev) => [
        ...prev,
        {
          response: res?.response,
        },
      ]);
      let res_1 = await getSourceData(user);
      if (res_1) {
        setSourceCallIn(true);
        setSourceDataCallIn(res_1?.transcript);
      }
      setGenerating(false);
      setInputData("");
    } catch (err) {
      setGenerating(false);
      console.log(err);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        handleQueryClick()
    }
  };

  return (
    <div className="input-wrapper">
      <div className="input-container-callIn">
        <input
          type="text"
          placeholder={placeholder}
          className="input-box-callIn"
          onChange={handleInputChange}
          value={inputValue}
          onKeyDown={handleKeyDown} 
        />
        <img
          className="arrow-button"
          src={arrow}
          alt=">"
          onClick={handleQueryClick}
        />
      </div>
    </div>
  );
}

export default SearchInputMedFusion;
