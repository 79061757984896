import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { ToastContainer } from "react-toastify";
import "./App.scss";

import { MyContext } from "./context/ContextApi";

import Document from "./components/documentQuery/Document";
import ExcistingDocument from "./components/documentQuery/ExcistingDocument";

import Zoom from "./components/zoom/Zoom";
import ZoomExisting from "./components/zoom/ZoomExisting";

import DataDetective from "./components/dataDetective/DataDetective";
import ExcistingDataDetective from "./components/dataDetective/ExcistingDataDective";

import ComDataDetective from "./components/comDataDetective/ComDataDetective";
import ComExcistingDataDetective from "./components/comDataDetective/ComExcistingDataDective";

import ListFiles from "./components/files/Files";
import DashboardQuery from "./components/dashboard/DashboardQuery";
import ExicistingDashboard from "./components/dashboard/ExicistingDashboard";

import TableauQuery from "./components/tableau/TableauQuery";
import ExicistingTableau from "./components/tableau/ExicistingTableau";

import ReadmeViewer from "./components/help/Help";
import PubmedQuery from "./components/pubmedSearch/Pubmed";
import GenInspector from "./components/genInspector/GenInspector";
import Admin from "./components/admin/Admin";
import Layout from "./layout/Layout";
import NewChat from "./components/Chat";
import ExicistingChat from "./components/Chat/Exicisting";

import DynamicChart from "./components/Chart/DynamicChart";
import ExicistingDynamicChart from "./components/Chart/ExicistingDynamicChart";

import ContentChange from "./components/ContentChange/ContentChange";
import ContentGeneration from "./components/ContentGeneration/contentGeneration";
import FileUpload from "./components/LanguageTranslation/fileUploading";

import { MsalProvider } from "@azure/msal-react";

// let LoginPage = lazy(() => import("./components/login/loginpage"));
import LoginPage from "./components/login/loginpage";
import MedLingo from "./components/LanguageTranslation/medLingo";
import MedPulse from "./components/LanguageTranslation/medPulse";
import MedInsights from "./components/LanguageTranslation/medInsights";
import SalesRepCopilot from "./components/SalesRepCopilot/SalesRepCopilot";

import MlrReview from "./components/MlrReview/mlrReview";

function App({ instance }) {
  const [loggedin, setLoggedin] = useState(false);
  const [allValues, setAllvalues] = useState();
  const [currentSession, setCurretSession] = useState("");
  const [filterContent, setFilterContent] = useState({
    source: [],
    relevance: "",
  });
  // const [post, setPost] = useState("");
  const [dashboardname, setDashboardname] = useState({
    name: "",
    region: "",
  });
  const [sessionUpdate, setSessionUpdate] = useState(false);
  const [newQuery, setNewQuery] = useState(false);
  const [collectionfetch, setCollectionfetch] = useState(false);
  const [userDropdDownValue, setUserDropDownValue] = useState({
    value: "OpenAI",
    label: "OpenAI",
  });
  const [dropDownPersona, setDropDownPersona] = useState();
  const [dropDownDashvistaData, setDropDownDashvistaData] = useState();
  const [personaValueList, setPersonaValueList] = useState();
  const [sessionPersonaVal, setSessionPersonaval] = useState();
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isDiseaseListShow, setIsDiseaseListShow] = useState(false);

  const [commonLoading, setCommonLoading] = useState(false);
  const [isGeneTableList, setGeneTableList] = useState([]);
  const [isGeneTableClear, setGeneTableClear] = useState(false);
  const [isFileUploadStatus, setIsFileUploadStatus] = useState(false);
  const [isSubmitGeneIns, setIsSubmitGeneIns] = useState(false);
  const [isApiCallLength, setIsApiCallLength] = useState(0);
  const [isNavMenuVisible, setIsNavMenuVisibile] = useState(false);

  const [isMedPulseData, setIsMedPulseData] = useState({});

  const startLoading = () => setCommonLoading(true);
  const stopLoading = () => setCommonLoading(false);

  const [personaOptions, setPersonaOptions] = useState([]);
  const [isClickUploadDoc, setIsClickUpload] = useState(false);
  const [fileContentList, setFileContentList] = useState([]);

  const [tableHeading, setTableHeading] = useState([]);
  const [isFilePath, setIsfilePath] = useState({});

  const [drugColumn, setDrugColumn] = useState([]);

  const [isFileStatusSuccess, setIsFileStatusSuccess] = useState();
  const [loaderEnable, setLoaderEnable] = useState(false);
  const [loaderEnableTwo, setLoaderEnableTwo] = useState(false);
  const [loaderEnableThree, setLoaderEnableThree] = useState(false);
  const [radioBtnOneEnable, setRadioBtnOneEnable] = useState(false);
  const [radioBtnTwoEnable, setRadioBtnTwoEnable] = useState(false);
  const [radioBtnThreeEnable, setRadioBtnThreeEnable] = useState(false);
  const [detectivePersonaVal, setDetectivePersoanVal] = useState();
  const [detectivePersonaTab, setDetectivePersonaTab] = useState();

  const [comPersonaVal, setComPersoanVal] = useState(null);
  const [comPersonaTab, setComPersonaTab] = useState(null);

  const [zoomCollValue, setZoomCollValue] = useState("");

  const [documents, setDocuments] = useState([]);
  const [selectedFileUrls, setSelectedFileUrls] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");

  const [orangeFlag, setOrangeFlag] = useState([]);
  const [redFlag, setRedFlag] = useState([]);
  const [claimsDataSrc, setClaimsDataSrc] = useState([]);
  const [currPageClaims, setCurrPageClaims] = useState();
  const [logoDataSrc, setLogoDataSrc] = useState([]);
  const [currPageLogo, setCurrPageLogo] = useState();
  const [selectedFile, setSelectedFile] = useState([]);

  const [faqInputValue, setFaqInputValue] = useState("");
  const [currentFaqVal, setCurrentFaqVal] = useState({
    currentPath: "",
    currentColl: "",
  });
  const [currentFaqValPubMed, setCurrentFaqValPubMed] = useState();

  //faq
  const [isFaq, setIsFaq] = useState(false);
  const [faqModalShow, setFaqModalShow] = useState(false);

  const clearDocuments = () => setDocuments([]);

  useEffect(() => {
    const login = sessionStorage.getItem("email");
    if (login) {
      setLoggedin(true);
    }
  }, [loggedin]);

  return (
    <>
      <MsalProvider instance={instance}>
        <MyContext.Provider
          value={{
            isTableVisible,
            setIsTableVisible,
            isDiseaseListShow,
            setIsDiseaseListShow,
            allValues,
            setAllvalues,
            sessionUpdate,
            setSessionUpdate,
            dashboardname,
            setDashboardname,
            filterContent,
            setFilterContent,
            newQuery,
            setNewQuery,
            currentSession,
            setCurretSession,
            collectionfetch,
            setCollectionfetch,
            personaOptions,
            setPersonaOptions,
            userDropdDownValue,
            setUserDropDownValue,
            dropDownPersona,
            setDropDownPersona,
            setSessionPersonaval,
            sessionPersonaVal,
            dropDownDashvistaData,
            setDropDownDashvistaData,
            commonLoading,
            startLoading,
            stopLoading,
            personaValueList,
            setPersonaValueList,
            setGeneTableList,
            isGeneTableList,
            setGeneTableClear,
            isGeneTableClear,
            setIsFileUploadStatus,
            isFileUploadStatus,
            setIsSubmitGeneIns,
            isSubmitGeneIns,
            isApiCallLength,
            setIsApiCallLength,
            isClickUploadDoc,
            setIsClickUpload,
            isNavMenuVisible,
            setIsNavMenuVisibile,
            tableHeading,
            setTableHeading,
            drugColumn,
            setDrugColumn,
            isMedPulseData,
            setIsMedPulseData,
            isFilePath,
            setIsfilePath,
            isFileStatusSuccess,
            setIsFileStatusSuccess,
            loaderEnable,
            setLoaderEnable,
            loaderEnableTwo,
            setLoaderEnableTwo,
            loaderEnableThree,
            setLoaderEnableThree,
            radioBtnOneEnable,
            radioBtnTwoEnable,
            radioBtnThreeEnable,
            setRadioBtnOneEnable,
            setRadioBtnTwoEnable,
            setRadioBtnThreeEnable,
            fileContentList,
            setFileContentList,
            documents,
            setDocuments,
            selectedFileUrls,
            setSelectedFileUrls,
            selectedFileType,
            setSelectedFileType,
            clearDocuments,
            detectivePersonaVal,
            setDetectivePersoanVal,
            detectivePersonaTab,
            setDetectivePersonaTab,
            zoomCollValue,
            setZoomCollValue,
            comPersonaVal,
            setComPersoanVal,
            comPersonaTab,
            setComPersonaTab,
            orangeFlag,
            setOrangeFlag,
            redFlag,
            setRedFlag,
            claimsDataSrc,
            setClaimsDataSrc,
            logoDataSrc,
            setLogoDataSrc,
            currPageClaims,
            setCurrPageClaims,
            currPageLogo,
            setCurrPageLogo,
            selectedFile,
            setSelectedFile,
            isFaq,
            setIsFaq,
            faqInputValue,
            setFaqInputValue,
            currentFaqVal,
            setCurrentFaqVal,
            currentFaqValPubMed,
            setCurrentFaqValPubMed,
            faqModalShow,
            setFaqModalShow,
          }}
        >
          {/* <ReadmeViewer /> */}
          <Routes>
            <Route
              path="/"
              index
              element={<LoginPage setLoggedin={setLoggedin} />}
            />
            <Route
              path="/newquery/:id"
              element={
                <Layout>
                  <ExicistingChat />
                </Layout>
              }
            />
            <Route
              path="/documentquery/:id"
              element={
                <Layout>
                  <ExcistingDocument />
                </Layout>
              }
            />
            <Route
              path="/zoom"
              index
              element={
                <Layout>
                  <Zoom />
                </Layout>
              }
            />
            <Route
              path="/zoom/:id"
              element={
                <Layout>
                  <ZoomExisting />
                </Layout>
              }
            />
            <Route
              path="/dataDetective"
              index
              element={
                <Layout>
                  <DataDetective />
                </Layout>
              }
            />
            <Route
              path="/dataDetective/:id"
              element={
                <Layout>
                  <ExcistingDataDetective />
                </Layout>
              }
            />

            <Route
              path="/comdataDetective"
              index
              element={
                <Layout>
                  <ComDataDetective />
                </Layout>
              }
            />
            <Route
              path="/comdataDetective/:id"
              element={
                <Layout>
                  <ComExcistingDataDetective />
                </Layout>
              }
            />

            <Route
              path="/seeFiles"
              element={
                <Layout>
                  <ListFiles />
                </Layout>
              }
            />
            <Route
              path="/dashboardquery"
              element={
                <Layout>
                  <DashboardQuery />
                </Layout>
              }
            />
            <Route
              path="/dashboardquery/:id"
              element={
                <Layout>
                  <ExicistingDashboard />
                </Layout>
              }
            />
            <Route
              path="/tableauquery"
              element={
                <Layout>
                  <TableauQuery />
                </Layout>
              }
            />
            <Route
              path="/contentChange"
              element={
                <Layout>
                  <ContentChange />
                </Layout>
              }
            />
            <Route
              path="/tableauquery/:id"
              element={
                <Layout>
                  <ExicistingTableau />
                </Layout>
              }
            />
            <Route
              path="/newquery"
              element={
                loggedin ? (
                  <Layout>
                    <NewChat />
                  </Layout>
                ) : (
                  <LoginPage setLoggedin={setLoggedin} />
                )
              }
            />
            <Route
              path="/admin/persona"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/user"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/collection"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/schedule"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/workBook"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/documentquery"
              index
              element={
                <Layout>
                  <Document />
                </Layout>
              }
            />
            <Route
              path="/:id/help"
              index
              element={
                <Layout>
                  <ReadmeViewer />
                </Layout>
              }
            />
            <Route
              path="/pubmedquery"
              index
              element={
                <Layout>
                  <PubmedQuery />
                </Layout>
              }
            />
            <Route
              path="/textchartquery"
              index
              element={
                <Layout>
                  <DynamicChart />
                </Layout>
              }
            />
            <Route
              path="/textchartquery/:id"
              index
              element={
                <Layout>
                  <ExicistingDynamicChart />
                </Layout>
              }
            />
            <Route
              path="/genInspector"
              index
              element={
                <Layout>
                  <GenInspector />
                </Layout>
              }
            />
            <Route
              path="/contentGeneration"
              element={
                <Layout>
                  <ContentGeneration />
                </Layout>
              }
            />
            <Route
              path="/fileUploading"
              element={
                <Layout>
                  <FileUpload />
                </Layout>
              }
            />
            <Route
              path="/medLingo"
              element={
                <Layout>
                  <MedLingo />
                </Layout>
              }
            />
            <Route
              path="/medPulse"
              element={
                <Layout>
                  <MedPulse />
                </Layout>
              }
            />
            <Route
              path="/medInsights"
              element={
                <Layout>
                  <MedInsights />
                </Layout>
              }
            />
            <Route
              path="/mlrReview"
              element={
                <Layout>
                  <MlrReview />
                </Layout>
              }
            />
            <Route
              path="/salesRepoCopilot"
              element={
                <Layout>
                  <SalesRepCopilot />
                </Layout>
              }
            />
          </Routes>
        </MyContext.Provider>
      </MsalProvider>

      <ToastContainer
        autoClose={1000}
        position="top-right"
        hideProgressBar
        theme="colored"
      />
      <Tooltip
        id="my-tooltip"
        style={{
          zIndex: "3",
          maxWidth: "50%",
          background: "black",
          fontSize: "0.85rem",
        }}
        place="bottom"
      />
      <Tooltip
        id="article"
        style={{
          zIndex: "3",
          maxWidth: "15%",
          background: "black",
          fontSize: "0.85rem",
        }}
        place="bottom"
      />
    </>
  );
}

export default App;
