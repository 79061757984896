import React, { useState, useEffect, useContext, useMemo } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ChatBot } from "../../common";
import { MyContext } from "../../context/ContextApi";
import { CommonLoader } from "../../common";
import { Sourcebar } from "../../common";
import { personalIdentityDataList } from "../../api/Postaction";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UploadLogo from "./logo";
import ClaimsTagging from "./claimsTagging";
import { toast } from "react-toastify";
import PDFViewer from "./pdfViewer";

const MlrReview = () => {
  const {
    newQuery,
    selectedFileUrls,
    filterContent,
    setSelectedFileUrls,
    orangeFlag,
    setOrangeFlag,
    redFlag,
    setRedFlag,
    setBaseUrl,
    baseClaimUrl,
  } = useContext(MyContext);
  const userName = sessionStorage.getItem("email");

  const [key, setKey] = useState("document");
  // const [orangeFlag, setOrangeFlag] = useState([]);
  // const [redFlag, setRedFlag] = useState([]);
  const [highlightedPdf, setHighlightedPdf] = useState("");
  //const [previewPdf, setPreviewPdf] = useState("");
  const [loading, setLoading] = useState(false);
  const [watermarkData, setWatermarkData] = useState([]);
  const [piiTextContent, setPiiTextContent] = useState([]);
  const [dataFetched, setDataFetched] = useState({
    pii: false,
    claims: false,
    logo: false,
  });
  const [highlightedPdfResponse, setHighlightedPdfResponse] = useState([]);
  const [highlightedSentences, setHighlightedSentences] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setOrangeFlag([]);
    setRedFlag([]);
    setLoading(false);
    setWatermarkData([]);
    setPiiTextContent([]);
    setSelectedFileUrls("");
    setHighlightedPdf("");
    setKey("document");
    setDataFetched({ pii: false, claims: false, logo: false });
  }, [newQuery]);

  const decodeAndExtractBaseUrl = (url) => {
    // Decode the entire URL to handle encoded characters
    const decodedUrl = decodeURIComponent(url);
    // Split the URL to separate the base part from the query parameters
    const urlParts = decodedUrl.split("?");
    // Decode the base part again to ensure all special characters are properly decoded
    const baseUrl = decodeURIComponent(urlParts[0]);
    return baseUrl;
  };

  // Ensure selectedFileUrls is defined and not empty
  const baseUrl = selectedFileUrls
    ? decodeAndExtractBaseUrl(selectedFileUrls)
    : null;

  // useEffect(() => {
  //   console.log("baseClaimUrl context", baseClaimUrl);
  // }, [baseClaimUrl]);

  useEffect(() => {
    if (selectedFileUrls && key === "pii") {
      fetchPIIData();
    }
  }, [selectedFileUrls]);

  setBaseUrl(baseUrl);

  const fetchPIIData = async () => {
    setLoading(true);
    console.info({ userName, selectedFileUrls });
    if (!userName || !selectedFileUrls || selectedFileUrls.length === 0) {
      toast.error("Email and PDF path are required");
      setLoading(false);
      return;
    }

    let body = {
      email: userName,
      pdf_path: selectedFileUrls,
    };

    try {
      const dataResponse = await personalIdentityDataList(body);

      if (dataResponse) {
        setHighlightedPdfResponse([
          ...dataResponse?.orange_flags,
          ...dataResponse?.red_flags,
          ...dataResponse?.watermark_detections,
          ...dataResponse?.pii_detections,
        ]);
        setOrangeFlag(dataResponse?.orange_flags);
        setRedFlag(dataResponse?.red_flags);
        setHighlightedPdf(dataResponse?.highlighted_pdf);
        setWatermarkData(dataResponse?.watermark_detections);
        setPiiTextContent(dataResponse?.pii_detections);
      }
    } catch (err) {
      setLoading(false);
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (selectedKey) => {
    setKey(selectedKey);
    if (selectedKey === "pii" && !dataFetched.pii) {
      fetchPIIData();
      setDataFetched((prevState) => ({ ...prevState, pii: true }));
    } else if (selectedKey === "claims" && !dataFetched.claims) {
      // fetchClaimsData();
      setDataFetched((prevState) => ({ ...prevState, claims: true }));
    } else if (selectedKey === "logo" && !dataFetched.logo) {
      // fetchLogoData();
      setDataFetched((prevState) => ({ ...prevState, logo: true }));
    }
  };

  // const memoizedDocViewer = useMemo(() => {
  //   return (
  //     <DocViewer
  //       pluginRenderers={DocViewerRenderers}
  //       documents={[{ uri: baseUrl }]}
  //       config={{
  //         header: {
  //           disableHeader: false,
  //           disableFileName: false,
  //           retainURLParams: false,
  //         },
  //       }}
  //     />
  //   );
  // }, [baseUrl]);

  const handlePageChange = (pageNumber) => {
    const sentences = highlightedPdfResponse.filter(
      (item) => item?.["Page No"] === pageNumber
    );
    setHighlightedSentences(sentences);
    setCurrentPage(pageNumber);
  };

  return (
    <div className="d-flex justify-content-between position-relative">
      <div className="contentScroll mainContent">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="col-10">
              <div className="row mt-5">
                <div className="col-12">
                  <div className="row justify-content-end">
                    <div className="col-auto"></div>
                  </div>
                </div>
                {selectedFileUrls.length > 0 ? (
                  <>
                    <Tabs
                      activeKey={key}
                      onSelect={handleSelect}
                      defaultActiveKey="document"
                      id="controlled-tab-example"
                      className="tabNavs"
                      justify
                    >
                      <Tab eventKey="document" title="Document">
                        <div className="card contentCard uploadFileScroll">
                          <iframe
                            src={baseUrl}
                            title="PDF Viewer"
                            style={{
                              border: "none",
                              width: "100%",
                              height: "900px",
                            }}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="pii" title="PII">
                        {loading && key === "pii" ? (
                          <div className="content-Loader">
                            <CommonLoader />
                          </div>
                        ) : (
                          <>
                            <div className="card contentCard uploadFileScroll">
                              <div className="col-12">
                                <iframe
                                  src={highlightedPdf}
                                  width="100%"
                                  height="900px"
                                  title="PDF Viewer"
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    height: "900px",
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Tab>
                      <Tab eventKey="claims" title="Claims">
                        <ClaimsTagging onPageChange={handlePageChange} />
                      </Tab>
                      <Tab eventKey="logo" title="Logo">
                        <UploadLogo />
                      </Tab>
                    </Tabs>
                  </>
                ) : (
                  <div className="genewrap__container">
                    <ChatBot userName={filterContent?.userName} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(key === "pii" || key === "claims" || key === "logo") && (
        <Sourcebar
          sourceKey={key}
          highlightedPdf={highlightedSentences}
          orangeFlag={orangeFlag}
          redFlag={redFlag}
          watermarkData={watermarkData}
          piiTextContent={piiTextContent}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default MlrReview;
