import React, { useContext, useState } from "react";
import { ChatBot } from "../../common";
import { MyContext } from "../../context/ContextApi";
import {
  CommonGenerateBtn,
  SearchInputArrowBtn,
  CommonPromChat,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import { Button, Modal } from "react-bootstrap";
import { staticData } from "./staticData";
import { showToast } from "../../utils/helpers/helpers";
import { errorStr } from "../../utils/constants/errorStrings";
import { getAnswerSalesRepCopilotData } from "../../api/Postaction";

function SalesRepCopilot() {
  const [inputValue, setInputValue] = useState();
  const [generating, setGenerating] = useState(false);
  const [data, setData] = useState([]);
  const [inputData, setInputData] = useState();

  const { filterContent, faqModalShow, setFaqModalShow } =
    useContext(MyContext);

  const handleSubmitFaq = () => {
    setFaqModalShow(false);
  };

  const closeModalFaq = () => {
    setFaqModalShow(false);
  };

  const generatePrompt = async () => {
    if (inputValue === "") {
      showToast(errorStr.pleaseEnterQuery);
    } else {
      setInputValue("");
      setGenerating(true);
      let body = {
        content: inputValue,
      };
      try {
        const response = await getAnswerSalesRepCopilotData(body);
        if (response !== undefined) {
          setData((prev) => [
            ...prev,
            {
              inputValue,
              response,
            },
          ]);
        }
        setGenerating(false)
      } catch (err) {
        console.log(err);
        setGenerating(false);
      }
    }
  };

  const handleQuestClicked = (val) => {
    setInputValue(val);
    setInputData(val);
    setFaqModalShow(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  return (
    <div className="mainContent w-100">
      {data.length === 0 ? (
        <ChatBot userName={filterContent?.userName} />
      ) : (
        <div className="prompt">
          {data &&
            data.length > 0 &&
            data.map((e, i) => (
              <CommonPromChat
                key={i}
                user={e?.inputValue}
                AI={e?.response}
                isDashboardSplit
                hidelike="true"
              />
            ))}
        </div>
      )}

      {generating && <CommonGenerateBtn title={commonStr.thinking} />}

      <SearchInputArrowBtn
        value={inputValue}
        placeholder={commonStr.typeYourQuery}
        onKeyPress={handleKeyPress}
        onChange={(e) => setInputValue(e.target.value)}
        inputValue={inputValue}
        onClick={generatePrompt}
        audioHide={false}
      />

      {faqModalShow && (
        <Modal
          show={faqModalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          {" "}
          <form onSubmit={handleSubmitFaq}>
            <Modal.Header closeButton={true}>
              <Modal.Title id="contained-modal-title-vcenter">FAQ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {staticData.map((val, index) => (
                <li
                  key={index}
                  onClick={() => handleQuestClicked(val)}
                  style={{ cursor: "pointer" }}
                >
                  {val}
                  <hr />
                </li>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModalFaq}>
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </div>
  );
}

export default SalesRepCopilot;
