const dev = process.env.REACT_APP_API_DEMO_URL; // Test
const hcp_Mlt_Demo = process.env.REACT_APP_API_DEMO_HCP_MLT_URL;
const persona = process.env.REACT_APP_API_DEMO_PERSONA;
const docSonar_bucketList = process.env.REACT_APP_API_DEMO_DOCSONAR_BUCKETLIST;
const pubmed = process.env.REACT_APP_API_DEMO_PUBMEDQUERY;
const content_tagging = process.env.REACT_APP_API_DEMO_CONTENT_TAGGING;
const medFusion = process.env.REACT_APP_API_DEV_URL_MED_FUSION;
const com_data_detect = process.env.REACT_APP_API_DEMO_URL_COM_DATA_DETECT;

export const postUrl = {
  sendToken: `${dev}/sso/authentication`,
  createPersona: `${persona}/data_lens/add_persona`,
  createUser: `${dev}/login/create_user/`,
  login: `${dev}/login/user_login/`,
  prompt: `https://geninsights.agilisiumtech.com:444/session/Prompt_chat/`,
  sessionlist: `${persona}/data_lens/get_sessions/`,
  sessionHistory: `${dev}/session/list_session_chat/`,
  audio: `${dev}/Email/create_audio/`,
  sendEmail: `${dev}/Email/send_email/`,
  shareWhatsApp: `${dev}/WhatsApp/send_message_text/`,
  kpi: `${dev}/kpi/summary/`,
  // uploadDocuments: `${dev}/document_search/upload_document/`,
  uploadDocuments: `https://geninsights.agilisiumtech.com:448/document_search/upload_document`,
  // uploadDocuments: `https://dev-geninsights.agilisiumtech.com:448/document_search/upload_document`,

  documentNewsession: `${dev}/document_session/new_doc_search_session/`,
  documentChathistory: `${dev}/document_session/Display_doc_search_session_chat/`,
  // documentPrompt: `${dev}/document_search/retrieve_from_document/`,
  documentPrompt: `https://geninsights.agilisiumtech.com:448/document_search/retrieve_from_document`,

  createCollection: `https://geninsights.agilisiumtech.com:462/login/create_collection/`,
  localDocument: `https://geninsights.agilisiumtech.com:448/document_search/local_doc_upload`,
  bucketList: `${docSonar_bucketList}/document_search/bucket_list`,
  dashboardPrompt: `${dev}/dashboard_search/dashboard_chat/`,
  summaryDashboard: `${dev}/dashboard_search/dashboard_summary/`,
  dashboardSessionHistory: `${dev}/dasboard_session/get_dashboard_search_sessions/`,
  dashboardChathistory: `${dev}/dasboard_session/Display_dashboard_session_chat/`,
  schedule: `${dev}/scheduler/create-rule/`,
  feedback: `${dev}/session/feedback-data/`,
  data_audio_history: `${dev}/session/data_audio_history/`,
  document_audio_history: `${dev}/document_session/document_audio_history/`,
  dashboard_audio_history: `${dev}/dasboard_session/dashboard_audio_history/`,
  pubmedSearch: `${pubmed}/pubmed/pubmed_search/`,
  referencePapers: `${pubmed}/pubmed/reference_papers/`,
  dataRenamesession: `${dev}/session/rename_data_search_sessions/`,
  documentRenamesession: `${dev}/document_session/rename_doc_search_sessions/`,
  dashboardRenamesession: `${dev}/dasboard_session/rename_dashboard_search_sessions/`,
  dashRenameSession: `${dev}/text_to_dashboard/rename_dash_vista_chat_ses/`,
  decodedContent: `https://geninsights.agilisiumtech.com:448/document_search/convert_path_to_encoded_content`,
  modalSwapLogs: `${dev}/login/model_swap_logs/`,
  upload_doc_pubmed: `${pubmed}/pubmed/upload_doc_pubmed/`,
  upload_local_document_pub: `${pubmed}/pubmed/upload_local_doc_pubmed/`,
  updating_disease_tagged: `${pubmed}/pubmed/updating_disease_tagged/`,
  textToDashboard: `${dev}/text_to_dashboard/txt_to_dash`,
  chartChatHistory: `${dev}/text_to_dashboard/display_dash_vista_history/`,
  chartSessionHistory: `${dev}/text_to_dashboard/get_dash_vista_sessions/`,
  pubmedSearchSummary: `${pubmed}/pubmed/pubmed_search_summary/`,
  audioPromptForData: `${dev}/session/Prompt_chat_speech/`,
  audioPromptForDoc: `${dev}/document_search/retrieve_from_document_speech/`,
  audioPromptForDash: `${dev}/dashboard_search/dashboard_chat_speech/`,
  new_chat_session: `${pubmed}/pubmed/new_chat_session/`,
  uri_endpoint: `${dev}/session/uri_endpoint/`,
  search_get_data_json: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/get_data_json/`,
  tableau_dashboard_summary: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_summary/`,
  tableau_dashboard_chat: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_chat/`,
  tableau_dashboard_chat_speech: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_chat_speech/`,
  tableauRenameSession: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/rename_dashboard_search_sessions/`,
  tableauSessionChat: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/Display_dashboard_session_chat/`,
  tableauAudioHistory: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/dashboard_audio_history/`,
  createWorkBook: `${dev}/login/create_workbook_kpi/`,
  fetchGeneData: `${dev}/gene_inspector/fetch_gene_data/`,
  uploadDocGen: `${dev}/gene_inspector/upload_local_doc_gene/`,
  addChartResponseInHistory: `${dev}/text_to_dashboard/adding_dash_vista_history/`,
  uploadDocumetGt: `${dev}/content_tagging/upload_document_ct/`,
  uploadLocalDocumetGt: `${content_tagging}/content_tagging/upload_local_document_ct/`,
  contentTaggingSearch: `${content_tagging}/content_tagging/content_tagging_search/`,
  contentDbList: `${content_tagging}/content_tagging/list_databases/`,
  contentTableList: `${content_tagging}/content_tagging/list_tables/`,
  contentTagTable: `${content_tagging}/content_tagging/insert_tags_into_table/`,
  // contentTemplateUpload: `${dev}/content_generator/upload-template/`,
  contentTemplateUpload: `https://geninsights.agilisiumtech.com:555/content_generator/upload-template/`,
  // contentGenerateInfo: `${dev}/content_generator/extract-info/`,
  contentGenerateInfo: `https://geninsights.agilisiumtech.com:555/content_generator/upload-info-doc/`,
  contentFileLists: `https://geninsights.agilisiumtech.com:555/content_generator/list-reference-file/`,
  contentGenerateReference: `https://geninsights.agilisiumtech.com:555/content_generator/extract-info-reference/`,
  contentRagaScore: `https://geninsights.agilisiumtech.com:555/content_generator/ragas-score/`,
  // contentGenerateDocument: `${dev}/content_generator/generate-document/`,
  contentGenerateDocument: `https://geninsights.agilisiumtech.com:555/content_generator/generate-document/`,
  dropCollection: `https://geninsights.agilisiumtech.com:555/content_generator/drop-collection/`,
  translateDocumentUpload: `${hcp_Mlt_Demo}/lang_trans_analytics/upload/`,
  translateColumnData: `${hcp_Mlt_Demo}/lang_trans_analytics/Med_Lingo/`,
  uniqueValueForColumnData: `${hcp_Mlt_Demo}/lang_trans_analytics/MedInsights/column_values/unique_values/`,
  dataResForMedInsight: `${hcp_Mlt_Demo}/lang_trans_analytics/Med_Insights/`,
  getColumnDataForMedPulse: `${hcp_Mlt_Demo}/lang_trans_analytics/Med_Pulse/`,
  uploadMlrDoc: `https://geninsights.agilisiumtech.com:446/pii_checker/upload_pdf`,
  personalIdentityData: `https://geninsights.agilisiumtech.com:446/pii_checker/process_pii_and_watermarks`,
  claimsTagging: `https://geninsights.agilisiumtech.com:446/pii_checker/claims_tagging`,
  uploadLogo: `https://geninsights.agilisiumtech.com:446/pii_checker/upload_logo_image`,
  // waterMarkDetectionData: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/detect-watermarks/`,
  // personalIdentityWaterMark: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/detect-pii-in-images/`,
  deleteCollectionListFile: `https://geninsights.agilisiumtech.com:555/content_generator/delete-ref-file-s3/`,
  deleteCollectionAllListFile: `https://geninsights.agilisiumtech.com:555/content_generator/delete-all-file-s3/`,

  dataDetectivePrompt: `https://geninsights.agilisiumtech.com:449/gen_data/detective_chat`,
  datadecRenamesession: `https://geninsights.agilisiumtech.com:449/session/rename_sessions/`,
  dataDetectiveSession: `https://geninsights.agilisiumtech.com:449/session/get_sessions/`,
  dataDetectiveChatHistory: `https://geninsights.agilisiumtech.com:449/session/list_session_chat/`,

  // commercial data detective
  comDetectivePrompt: `${com_data_detect}/gen_data/detective_chat`,
  comDetectiveSession: `${com_data_detect}/session/get_sessions/`,
  comDetetectiveRename: `${com_data_detect}/session/rename_sessions/`,
  comDetectiveChatHistory: `${com_data_detect}/session/list_session_chat/`,
  comDetectiveUpload: `${com_data_detect}/session/upload_file/`,

  getEvaluate: `https://geninsights.agilisiumtech.com:448/document_search/get_evaluate_response`,
  getEvaluateTableau: `https://geninsights.agilisiumtech.com:451/tableau_dashboard_search/get-evaluate_response/`,

  getEvaluateDataLens: `https://geninsights.agilisiumtech.com:452/data_lens/evaluate-response/`,

  //zoom api
  zoomRetreivePrompt: `https://geninsights.agilisiumtech.com:450/document_search/retrieve_from_transcribed_document`,
  zoomSessionHistory: `https://geninsights.agilisiumtech.com:450/document_session/get_doc_search_sessions`,
  zoomRenamesession: `https://geninsights.agilisiumtech.com:450/document_session/rename_doc_search_sessions`,
  zoomAudioHistroy: `https://geninsights.agilisiumtech.com:450/document_session/document_audio_history`,
  zoomAudioConvert: `https://geninsights.agilisiumtech.com:450}/Email/create_audio`,

  zoomChathistory: `https://geninsights.agilisiumtech.com:450/document_session/display_doc_search_session_chat`,
  zoomDecodeContent: `https://geninsights.agilisiumtech.com:450/document_search/convert_path_to_encoded_content`,

  zoomVideoConvert: `https://geninsights.agilisiumtech.com:450/document_search/upload_local_video`,

  documentSessionHistory: `https://geninsights.agilisiumtech.com:448/document_session/get_doc_search_sessions`,
  tableauGetSessions: `https://geninsights.agilisiumtech.com:451/tableau_dasboard_session/get_dashboard_search_sessions/`,

  getAnswerSalesRepCopilot: `https://geninsights.agilisiumtech.com:461/document_search/new_project`,
  getDatalensDropdownList: `${persona}/data_lens/get_dl_persona/`,

  //Med Fusion
  uploadMedFusion: `${medFusion}/audio_mapper/upload_local_audio`,
  extractedFiles: `${medFusion}/audio_mapper/extract-data/`,
  downloadData: `${medFusion}/audio_mapper/api/download-csv/`,
  queryMedFusion: `${medFusion}/audio_mapper/valid-query/`,
};

export const getUrl = {
  tables: `${dev}/login/list_tables/`,
  persona: `${dev}/login/list_persona/ `,
  users: `${dev}/login/list_user/`,
  tableAccess: `${dev}/login/get-persona-data/`,
  initialSetup: `${dev}/document_search/initial_setup/`,
  listCollection: `${dev}/login/list_collection/`,
  dashboardRegions: `${dev}/dashboard_search/list_regions/`,
  listDashboards: `${dev}/dashboard_search/list_dashboards/`,
  personauser: `${dev}/login/get_persona_tagged_users/`,
  getPersonaList: `${dev}/login/get-persona-list/`,
  getModelType: `${dev}/login/get_model_type/`,
  get_disease_tagged: `${pubmed}/pubmed/get_disease_tagged/`,
  get_all_disease_tagged: `${pubmed}/pubmed/get_all_disease_tagged`,
  tableau_list_project: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/list_project/`,
  tableau_list_workbook: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/list_workbook/`,
  getWorkBookKpi: `${dev}/login/list_workbookkpi/`,
  getWorkBookTable: `${dev}/login/list_workbookkpi_table/`,
  geneInsGeData: `${dev}/gene_inspector/get_data/`,
  downloadDocument: `https://geninsights.agilisiumtech.com:555/content_generator/download_docx/`,
  dataLens: `https://geninsights.agilisiumtech.com:444/data_lens_faq/datalens_get_faqs/`,
  docSonarFaq: `https://geninsights.agilisiumtech.com:448/login/list_faqs/`,
  commericalFaq: `${com_data_detect}/login/list_faqs/`,
  pubmedFaq: `https://geninsights.agilisiumtech.com:444/pubmed_faq/pubmed_get_faqs/`,
  dataVistaFaq: `https://geninsights.agilisiumtech.com:444/dash_vista_faq/dashvista_get_faqs/`,
  getDefaultSentiments: `${hcp_Mlt_Demo}/lang_trans_analytics/default_sentiments/`,

  //Med Fusion
  getSource: `${medFusion}/audio_mapper/transcription/`,
};

export const putUrl = {
  putPersona: `${persona}/data_lens/edit_persona/`,
  putUser: `${dev}/login/edit_user/`,
  editCollection: `${dev}/login/edit_collection/`,
  editWorkBook: `${dev}/login/edit_workbook_kpi/`,
  editPersona: `https://geninsights.agilisiumtech.com:452/data_lens/edit_persona/`,
};

export const deleteUrl = {
  deletePersona: `${dev}/login/delete_persona/`,
  deleteUser: `${dev}/login/delete_user/`,
  deleteSession: `${dev}/session/clear_sessions/`,
  deleteAllSession: `${dev}/session/delete_entire_session/`,
  deletedocument: `${dev}/document_session/clear_doc_search_sessions/`,
  deleteAlldocument: `${dev}/document_session/Delete_entire_doc_search_session/`,
  deleteCollection: `${dev}/login/delete_collection`,
  deletedashboardsession: `${dev}/dasboard_session/clear_dashboard_search_sessions/`,
  deleteAlldashboardsession: `${dev}/dasboard_session/Delete_entire_dashboard_search_session/`,
  delete_file_s3_pub: `${pubmed}/pubmed/delete_file_s3_pub/`,
  delete_disease_tagged_db: `${pubmed}/pubmed/delete_disease_tagged_db/`,
  deleteTableauSession: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/clear_dashboard_search_sessions/`,
  deleteTableauSessionAll: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/Delete_entire_dashboard_search_session/`,
  deleteAllSessioninDash: `${dev}/text_to_dashboard/Delete_entire_dash_vista_session/`,
  deleteSessionIdinDash: `${dev}/text_to_dashboard/clear_dash_vista_sessions/`,
  deleteWorkBookKpi: `${dev}/login/delete_workbook_kpi/`,
  deleteSingleFile: `https://geninsights.agilisiumtech.com:448/document_search/delete_single_file/`,
  deleteCollectionCt: `${content_tagging}/content_tagging/delete_collection_ct/`,

  dataDetecSessionId: `https://geninsights.agilisiumtech.com:449/session/clear_sessions/`,
  dataDetecSessionAll: `https://geninsights.agilisiumtech.com:449/session/delete_entire_session/`,

  comDetecSessionId: `${com_data_detect}/session/clear_sessions/`,
  comDetecSessionAll: `${com_data_detect}/session/delete_entire_session/`,

  ZoomDetecSessionId: `https://geninsights.agilisiumtech.com:450/document_session/clear_doc_search_sessions`,
  ZoomDetecSessionAll: `https://geninsights.agilisiumtech.com:450/document_session/Delete_entire_doc_search_session`,

  deleteOnePersona: `https://geninsights.agilisiumtech.com:452/data_lens/delete_persona/`,
};
