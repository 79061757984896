import React, { useContext, useEffect, useState } from "react";
import ExtractedFile from "./ExtractedFile";
import "./CommonMedFusion.css";
import { MyContext } from "../../context/ContextApi";
import { extractedFilesData } from "../../api/Postaction";
import Promptpage from "./Promptpage";

function MedFusionPromptFile() {
  const { fileNameExtract, newQuery, setQueryResponse } = useContext(MyContext);
  const email = sessionStorage.getItem("firstname");

  const [loading, setLoading] = useState(false);
  const [dataExtract, setDataExtract] = useState();

  useEffect(() => {
    setLoading(true);
    let body = {
      user_name: email,
      filename: fileNameExtract,
    };
    const fetchExtractedData = async () => {
      try {
        let res = await extractedFilesData(body);
        setLoading(false);
        setDataExtract(res);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchExtractedData();
  }, []);

  useEffect(() => {
    setDataExtract("");
    setQueryResponse("");
  }, [newQuery]);

  return (
    <div className="commonPromptFilePage-header">
      <div className="promptPage">
        <Promptpage />
      </div>

      <div className="vertical-line" />

      <div className="extractedFiles_page">
        <ExtractedFile loading={loading} dataExtract={dataExtract} />
      </div>
    </div>
  );
}

export default MedFusionPromptFile;
