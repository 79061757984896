import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;


const PDFViewer = ({ highlightedPdf, onPageChange }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageHeights, setPageHeights] = useState([]);

  // console.log(claimsData, "claimsDataPDfViewer");

  useEffect(() => {
    const handleScroll = () => {
      const pdfContainer = document.getElementById("pdf-container");
      const scrollTop = pdfContainer.scrollTop;

      let currentPage = 1;
      let currentPageTop = 0;

      for (let i = 0; i < pageHeights.length; i++) {
        currentPageTop += pageHeights[i];
        if (currentPageTop > scrollTop) {
          currentPage = i + 1;
          break;
        }
      }
      onPageChange(currentPage);
    };
    const container = document.getElementById("pdf-container");
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [pageHeights, onPageChange]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageHeights(Array(numPages).fill(0));
  };

  const onPageRenderSuccess = (pageNumber, { height }) => {
    setPageHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[pageNumber - 1] = height;
      return newHeights;
    });
  };

  return (
    <div
      id="pdf-container"
      style={{ overflow: "scroll", height: "600px", width: "650px" }}
    >
      <Document file={highlightedPdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            onRenderSuccess={(info) => onPageRenderSuccess(index + 1, info)}
          />
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
