import React, { useState, useContext } from "react";
import { claimsReview } from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import { toast } from "react-toastify";
import "./claimsReview.css";
import uuid from "react-uuid";

const ReviewBox = () => {
  const { baseurl, setIsBaseUrlUpdate } = useContext(MyContext);
  const [inputValue, setInputValue] = useState("");
  const [separatedValues, setSeparatedValues] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [isReviewed, setIsReviewed] = useState(false);

  const userName = sessionStorage.getItem("email");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const valuesArray = value
      .split(",")
      .map((item) => item.trim())
      .filter(Boolean);
    setSeparatedValues(valuesArray);
  };

  const handleReview = async () => {
    if (!inputValue.trim()) return;
    setIsBaseUrlUpdate("");
    const payload = {
      pdf_path: baseurl,
      claim_list: separatedValues,
      email: userName,
    };

    const toastId = toast.loading("Processing review...");

    try {
      const response = await claimsReview(payload);

      if (response?.data) {
        setReviewData(response.data.table_tags || []);
        setIsReviewed(true);

        if (response?.data?.highlighted_url?.length > 0) {
          console.log(
            "response?.data?.highlighted_url[0]",
            response?.data?.highlighted_url[0]
          );
          setIsBaseUrlUpdate(response?.data?.highlighted_url[0] + "/" + uuid());
        }

        toast.update(toastId, {
          render: "Review completed successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        throw new Error("No data received");
      }
    } catch (error) {
      console.error("Error while updating Title Rename", error);

      toast.update(toastId, {
        render: "Error processing review. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleReview();
    }
  };

  return (
    <div className="review-box-container">
      <div className="fixed-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter Claims Review"
          className="input-field"
          onKeyDown={handleKeyPress}
        />
        <button className="claimReview-btn" onClick={handleReview}>
          Claims Review
        </button>
      </div>

      {isReviewed && (
        <div className="review-results-container">
          {reviewData.length > 0 ? (
            reviewData.map((item, index) => (
              <div key={index} className="review-box">
                <span className="review-tag">{item.tag}</span>
                <span className="score">
                  {item.score_percentage
                    ? `${item.score_percentage.toFixed(2)}%`
                    : "100%"}
                </span>
                <span
                  className={`status ${
                    item.match_status === "Passed" ? "passed" : "failed"
                  }`}
                >
                  {item.match_status || "Passed"}
                </span>
              </div>
            ))
          ) : (
            <p className="no-data-message">No results found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewBox;
