import React, { useContext, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { FiShare } from "react-icons/fi";
import { HiThumbUp, HiThumbDown } from "react-icons/hi";
import { circleBot, userBot } from "../../utils/images";
import ThreeDotSpinner from "../threeDotSpinner";
import CommonModal from "../commonModal";
import { commonStr } from "../../utils/constants/commonStrings";
import { getEvaluateApi } from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import { useLocation } from "react-router";
import axios from "axios";
import "./styles.scss";
import { LuExternalLink } from "react-icons/lu";
import Plot from "react-plotly.js";
import { FaTableList } from "react-icons/fa6";
import { FaChartPie } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

function CommonPromChat({
  indexValue,
  user,
  AI,
  plotChart,
  audio,
  thumbsUp,
  thumbsDown,
  onThumbsUpClick,
  onThumbsDownClick,
  onShareClick,
  onShareNumberClick,
  isViewSource = false,
  onClickViewSource,
  isDashboardSplit = false,
  audioVisble = true,
  evaluateShow = false,
  listData,
  updateEvaluateData = {},
  hidelike = true,
  hidePayload = 1,
  isShowDetective = false,
  hideInfoDetective = false,
  tableVisibile = false,
  assist_agent,
  source,
  table,
  onSourceData,
  plot,
  image,
}) {
  const { allValues } = useContext(MyContext);
  const [evaluateModal, setEvaluateModal] = useState(false);
  const [dataDetectiveModal, setDataDetetectiveModal] = useState(false);
  const [plotChartModal, setplotChartModal] = useState(false);
  const [tableModal, settableModal] = useState(false);
  const [evaluateData, setEvaluateData] = useState([]);
  const [dataDetectiveList, setDataDetectiveList] = useState([]);
  var headers = [];
  if (tableVisibile) {
    headers = Array.from(
      new Set(listData?.tableList.flatMap((obj) => Object.keys(obj)))
    );
  }

  const imageUrl = `data:image/jpeg;base64,${image}`;

  const [sourceData, setSourceData] = useState();

  //location
  const location = useLocation();
  const currentPath = location.pathname.substring(1);

  const handleClickInfoIcon = () => {
    if (listData?.evaluate) {
      setEvaluateData(listData?.evaluate);
      updateEvaluateData(listData?.evaluate);
      setEvaluateModal(true);
    } else {
      handleEvaluateApi();
    }
  };

  const handleEvaluateApi = async () => {
    let body = {};

    if (hidePayload === 1) {
      body = {
        result: AI,
        collection_name: allValues.collection,
      };
    }
    if (hidePayload === 2) {
      body = {
        user_response: AI,
      };
    }
    if (hidePayload === 3) {
      body = {
        output: AI,
      };
    }

    let evaluateResponse = await getEvaluateApi(body, hidePayload);
    if (evaluateResponse) {
      updateEvaluateData(evaluateResponse);
      setEvaluateData(evaluateResponse);
      setEvaluateModal(true);
    }
  };

  const handleDataModal = () => {
    setDataDetectiveList(listData?.steps);
    setDataDetetectiveModal(true);
  };
  //pdf
  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }
  //ppt
  function base64toBlobPpt(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    });
  }
  //doc
  function base64toBlobDoc(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  }

  const onSourceClick = async () => {
    let body = { file_name: source };
    try {
      let res = await axios.post(
        "https://dev-geninsights.agilisiumtech.com:456/query_endpoint/convert_path_to_encoded_content",
        body
      );
      setSourceData(res.data);
      onSourceData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const openPdf = async () => {
    if (!sourceData) {
      console.error("sourceData is undefined or null");
      return;
    }
    const cleanedSourceData = sourceData.replace(/\s+/g, "");
    //pdf
    if (source.endsWith(".pdf")) {
      let blob = base64toBlob(cleanedSourceData);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(`${blobUrl}#page=${sourceData + 1}`);
      }
    }
    //ppt
    if (source.endsWith(".pptx")) {
      let blob = base64toBlobPpt(cleanedSourceData);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pptBase64.pptx");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(`${blobUrl}`, "_blank");
      }
    }
    //docx
    if (source.endsWith(".docx")) {
      let blob = base64toBlobDoc(cleanedSourceData);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "docBase64.docx");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      }
    }
  };

  const handleplotchart = () => {
    setplotChartModal(true);
  };

  const handletable = () => {
    settableModal(true);
  };

  return (
    <div>
      <div className="questions">
        <img src={userBot} alt="circleBot" />
        &ensp;&ensp;
        <div>{user}</div>
      </div>
      <div className="answers">
        <div className="ml-1">
          <img src={circleBot} alt="circleBot" />
        </div>
        &ensp;
        <div className="sentences">
          {isDashboardSplit ? (
            <ul>
              {AI?.split("\n").map((paragraph, pIndex) => (
                <p key={pIndex}>{paragraph}</p>
              ))}
            </ul>
          ) : (
            <ReactMarkdown>{AI}</ReactMarkdown>
          )}

          {currentPath.includes("omnilens") && (
            <>
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "blue" }}>Agent:</span>
                <span style={{ color: "green" }}> {assist_agent}</span>
                &nbsp; | &nbsp;
                <span style={{ color: "blue" }}>Source:</span>
                {assist_agent === "doc_search" ? (
                  <span onClick={onSourceClick} style={{ cursor: "pointer" }}>
                    {source} <LuExternalLink onClick={openPdf} />
                  </span>
                ) : (
                  <span style={{ color: "green" }}> {source}</span>
                )}
              </div>
              &nbsp;
              <div>
                <h5>Relevant Image:</h5>
                {image ? (
                  <img
                    src={imageUrl}
                    alt="img"
                    style={{ width: "700px", height: "auto" }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </div>
            </>
          )}

          {currentPath.includes("omnilens") && table?.length > 0 && (
            <div>
              <span style={{ fontWeight: "bold", color: "red" }}>
                Table Data
              </span>
              <table
                border="1"
                style={{ maxWidth: "40%", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    {Object.keys(table[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody border="1">
                  {table.map((item, index) => (
                    <tr key={index}>
                      {Object.values(item).map((value, i) => (
                        <td key={i}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {isShowDetective && (
            <div className="thumbs-icons mt-2">
              <div
                className={`thumbs-icon ${thumbsUp === true ? "active" : ""}`}
                onClick={onThumbsUpClick} // TODO
              >
                <HiThumbUp />
              </div>
              <div
                className={`thumbs-icon ${
                  thumbsDown === true ? "activedown" : ""
                }`}
                onClick={onThumbsDownClick} // TODO
              >
                <HiThumbDown />
              </div>

              {hideInfoDetective && (
                <div className={`thumbs-icon`}>
                  <IoMdInformationCircle
                    className="iconHover"
                    size={25}
                    color="black"
                    onClick={handleDataModal}
                  />
                </div>
              )}
            </div>
          )}

          {!currentPath.includes("omnilens") &&
            !currentPath.includes("salesRepCopilot") &&
            audioVisble && (
              <div className="audioplayer">
                {audio[indexValue] !== undefined ? (
                  <>
                    <audio controls style={{ maxWidth: "100%" }}>
                      <source src={audio[indexValue].audio} type="audio/mp3" />
                    </audio>
                    <div className="dropdown d-flex">
                      <button
                        type="button"
                        id="dropdownMenu1"
                        data-toggle="dropdown"
                      >
                        <FiShare size={20} color="#ea356f" />
                      </button>
                      {evaluateShow && (
                        <IoMdInformationCircle
                          className="iconHover"
                          size={25}
                          color="#ea356f"
                          onClick={handleClickInfoIcon}
                        />
                      )}

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu1"
                      >
                        <li>
                          <p
                            className="shareOptions"
                            onClick={onShareNumberClick}
                          >
                            WhatsApp
                          </p>
                        </li>
                        <li>
                          <p className="shareOptions" onClick={onShareClick}>
                            Outlook
                          </p>
                        </li>
                        <li>
                          <p className="shareOptions">Teams</p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <ThreeDotSpinner />
                )}
              </div>
            )}

          {!currentPath.includes("omnilens") &&
            !currentPath.includes("salesRepCopilot") &&
            audioVisble &&
            audio[indexValue] !== undefined &&
            tableVisibile && (
              <div className="w-100 table-sticky">
                <table className="desc-tab__tables">
                  <thead>
                    <tr className="desc-tab__tables__rowheading">
                      {headers?.map((header, index) => (
                        <th className="desc-tab__tables__rowborder" key={index}>
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {listData?.tableList?.map((item, index) => (
                      <tr key={index}>
                        {headers?.map((header, index) => (
                          <td
                            className="desc-tab__tables__databorder"
                            key={index}
                          >
                            {item[header]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

          {isViewSource && (
            <div className="viewSource" onClick={onClickViewSource}>
              View Source
            </div>
          )}
        </div>
        {!currentPath.includes("omnilens") && hidelike && (
          <div className="thumbs-icons">
            <div
              className={`thumbs-icon ${thumbsUp === true ? "active" : ""}`}
              onClick={onThumbsUpClick} // TODO
            >
              <HiThumbUp />
            </div>
            <div
              className={`thumbs-icon ${
                thumbsDown === true ? "activedown" : ""
              }`}
              onClick={onThumbsDownClick} // TODO
            >
              <HiThumbDown />
            </div>
            {currentPath.includes("newquery") && (
              <>
                <div className="thumbs-icon" onClick={handleplotchart}>
                  <FaChartPie />
                </div>
                <div className="thumbs-icon" onClick={handletable}>
                  <FaTableList />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {plot !== undefined && (
        <div>
          <Plot
            data={plot?.data}
            layout={{
              ...plot?.layout,
              title: {
                ...plot?.layout?.title,
                font: {
                  size: 14,
                },
                x: 0.5,
                xanchor: "center",
              },
            }}
          />
        </div>
      )}
      {evaluateModal && (
        <CommonModal
          showModal={evaluateModal}
          title={"Evaluate"}
          content={"Evalute"}
          onCancel={() => setEvaluateModal(false)}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          evaluateData={evaluateData}
          evaluateShow={evaluateShow}
        />
      )}
      {dataDetectiveModal && (
        <CommonModal
          showModal={dataDetectiveModal}
          title={"Chain Of Thoughts"}
          onCancel={() => setDataDetetectiveModal(false)}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          dataDetShow
          dataList={dataDetectiveList}
        />
      )}

      {plotChartModal && (
        <CommonModal
          showModal={plotChartModal}
          title={"Plot Chart"}
          onCancel={() => setplotChartModal(false)}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          dataDetPlotShow={true}
          dataList={plotChart}
        />
      )}

      {tableModal && (
        <CommonModal
          showModal={tableModal}
          title={"Table"}
          onCancel={() => settableModal(false)}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          dataDettableShow={true}
          tableList={listData}
        />
      )}
    </div>
  );
}

export default CommonPromChat;
