import arrow from "../../assets/svg/arrow.svg";
import userIcon from "../../assets/loginPage/userIcon.svg";
import lockIcon from "../../assets/loginPage/lock.svg";

//readme
import Logo from "../../assets/readme/agilisium_logo.png";
import Login from "../../assets/readme/welcome_back.png";
import Datasearch from "../../assets/readme/gen_insights_data_search.png";
import featureSwitch from "../../assets/readme/feature_swtich.png";
import newsearch from "../../assets/readme/new_search.png";
import Datasearchchat from "../../assets/readme/data_search_chat.png";
import option from "../../assets/readme/option.png";
import audioShare from "../../assets/readme/audio_share.png";
import send_mail from "../../assets/readme/send_mail.png";
import delete_icon from "../../assets/readme/delete_icon.jpeg";
import session from "../../assets/readme/session.png";
import clear_history from "../../assets/readme/clear_history.png";
import generate_kpi from "../../assets/readme/generate_kpi.png";
import select_kpi from "../../assets/readme/select_kpi.png";
import gen_insights_document_search from "../../assets/readme/gen_insights_document_search.png";
import upload_icon from "../../assets/readme/upload_icon.png";
import upload_window from "../../assets/readme/upload_window.png";
import collection_swtich from "../../assets/readme/collection_swtich.png";
import see_files_button from "../../assets/readme/see_files_button.png";
import see_files_window from "../../assets/readme/see_files_window.png";
import dashboard_window from "../../assets/readme/dashboard_window.png";
import dropdown_icon from "../../assets/readme/dropdown_icon.png";
import admin_option from "../../assets/readme/admin_option.png";
import user_details_window from "../../assets/readme/user_details_window.png";
import add_user_icon from "../../assets/readme/add_user_icon.png";
import add_user_window from "../../assets/readme/add_user_window.png";
import user_details_action from "../../assets/readme/user_details_action.png";
import edit_icon from "../../assets/readme/edit_icon.png";
import persona_details from "../../assets/readme/persona_details.png";
import create_persona_icon from "../../assets/readme/create_persona_icon.png";
import add_persona_window from "../../assets/readme/add_persona_window.png";
import collection_window from "../../assets/readme/collection_window.png";
import create_collection_icon from "../../assets/readme/create_collection_icon.png";
import add_collection_window from "../../assets/readme/add_collection_window.png";
import schedule_window from "../../assets/readme/schedule_window.png";
import logout from "../../assets/readme/logout.png";

//sidebar
import messageIcon from "../../assets/sideBar/messageIcon.svg";
import messageIcon1 from "../../assets/sideBar/messageIcon1.svg";
import groupUserIcon from "../../assets/sideBar/groupUser.svg";
import profileIcon from "../../assets/sideBar/user.svg";

//navbar
import datasearch_small from "../../assets/svg/datasearch_small.svg";
import documentsearch_small from "../../assets/svg/documentsearch_small.svg";
import dashboardsearch_small from "../../assets/svg/dashboardsearch_small.svg";
import pubmedsearch_small from "../../assets/svg/pubmedsearch_small.svg";
import datasearch_large from "../../assets/svg/datasearch_large.svg";
import documentsearch_large from "../../assets/svg/documentsearch_large.svg";
import dashboardsearch_large from "../../assets/svg/dashboardsearch_large.svg";
import pubmedsearch_large from "../../assets/svg/pubmedsearch_large.svg";
import menu1 from "../../assets/svg/menu1.svg";
import view from "../../assets/svg/view.svg";
import menu from "../../assets/svg/menu.svg";
import mlrIcon from "../../assets/svg/mlr.svg";

import bot from "../../assets/svg/bot.svg";
import circleBot from "../../assets/svg/cirlceBot.svg";
import userBot from "../../assets/svg/userBot.svg";
import searchIcon from "../../assets/svg/searchIcon.svg";
import shareIcon from "../../assets/svg/shareIcon.svg";
import langIcon from "../../assets/svg/lang.svg";

import datasearch_large1 from "../../assets/navbarMenu/datasearch_large.png";
import documentsearch_large1 from "../../assets/navbarMenu/documentsearch_large.png";
import dashboardsearch_large1 from "../../assets/navbarMenu/dashboardsearch_large.png";
import pubmedsearch_large1 from "../../assets/navbarMenu/pubmedsearch_large.png";
import orange_flag from "../../assets/svg/orange.jpg";
import red_flag from "../../assets/svg/red.jpg";

import aglogo from "../../assets/readme/AG.png";
import cancel from "../../assets/readme/cancel.png";
import downArrow from "../../assets/readme/downArrow.png";
import upArrow from "../../assets/readme/upArrow.png";

//gif
import uploadingDoc from "../../assets/readme/uploading.gif";
import threeDots from "../../assets/readme/threeDots.gif";
import search from "../../assets/readme/search.gif";
import deleteMedFusion from "../../assets/readme/delete.gif"

//ContentGeneration - Assets

import EditorBot from '../../assets/svg/EditorBot.svg';
import AnimationDocument from '../../assets/svg/AnimationDocument.gif';
import documentImage from '../../assets/svg/documentImage.svg';
import ScanDocument from '../../assets/svg/ScanDocument.gif';
import PreloaderDocument from '../../assets/svg/PreloaderDocument.gif';
import EditAI from '../../assets/svg/EditAI.svg';
import Template0 from '../../assets/svg/Template0.png';
import Template1 from "../../assets/svg/Template1.png";
import Template2 from "../../assets/svg/Template2.png";
import HighlightTemplate0 from "../../assets/svg/HighlightTemplate0.png";
import HighlightTemplate1 from "../../assets/svg/HighlightTemplate1.png";
import HighlightTemplate2 from "../../assets/svg/HighlightTemplate2.png";
import AnimationDynamicBanner from "../../assets/svg/AnimationDynamicBanner.gif";
import AnimationFileExtract from "../../assets/svg/AnimationFileExtract.gif";
import AnimationFileUpload from "../../assets/svg/AnimationFileUpload.gif";
import PDF from "../../assets/svg/PDF.svg";
import DOCX from "../../assets/svg/DOCX.svg";
import PPT from "../../assets/svg/PPT.svg";
import Teams from "../../assets/svg/Teams.svg";
import Gmail from "../../assets/svg/Gmail.svg";
import Slack from "../../assets/svg/Slack.svg";



// export svg images
export {
  arrow,
  userIcon,
  lockIcon,
  Logo,
  Login,
  Datasearch,
  featureSwitch,
  newsearch,
  Datasearchchat,
  option,
  audioShare,
  send_mail,
  delete_icon,
  session,
  clear_history,
  generate_kpi,
  select_kpi,
  gen_insights_document_search,
  upload_icon,
  upload_window,
  collection_swtich,
  see_files_button,
  see_files_window,
  dashboard_window,
  dropdown_icon,
  admin_option,
  user_details_window,
  add_user_icon,
  add_user_window,
  user_details_action,
  edit_icon,
  persona_details,
  create_persona_icon,
  add_persona_window,
  collection_window,
  create_collection_icon,
  add_collection_window,
  schedule_window,
  logout,
  messageIcon,
  messageIcon1,
  groupUserIcon,
  profileIcon,
  datasearch_small,
  documentsearch_small,
  dashboardsearch_small,
  pubmedsearch_small,
  datasearch_large,
  documentsearch_large,
  dashboardsearch_large,
  pubmedsearch_large,
  menu1,
  view,
  menu,
  bot,
  circleBot,
  userBot,
  searchIcon,
  shareIcon,
  datasearch_large1,
  documentsearch_large1,
  dashboardsearch_large1,
  pubmedsearch_large1,
  langIcon,
  mlrIcon,
  orange_flag,
  red_flag,
  aglogo,
  cancel,
  uploadingDoc,
  threeDots,
  search,
  downArrow,
  upArrow,
  deleteMedFusion,
  EditorBot,
  AnimationDocument,
  documentImage,
  ScanDocument,
  PreloaderDocument,
  EditAI,
  Template0,
  Template1,
  Template2,
  HighlightTemplate0,
  HighlightTemplate1,
  HighlightTemplate2,
  AnimationDynamicBanner,
  AnimationFileExtract,
  AnimationFileUpload,
  PPT,
  PDF,
  DOCX,
  Teams,
  Gmail,
  Slack,
};
