//import axios from "axios";

import axiosApi from "../interceptors/axiosinterceptor";
import { getUrl } from "./Endpoint";
import { toast } from "react-toastify";

export const userList = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.users}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in get user list", err);
  }
};

export const tableauLstProject = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.tableau_list_project}${id}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const tableau_list_workbook = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.tableau_list_workbook}${id}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getWorkBookApi = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.getWorkBookKpi}${id}/`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getWorkBookList = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.getWorkBookTable}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getGeneData = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.geneInsGeData}${id}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

//download file
export const downloadFileContent = async (userName) => {
  try {
    const response = await axiosApi.get(
      `${getUrl.downloadDocument}?collection_name=${userName}`,
      {
        responseType: "blob",
      }
    );
    const url = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "generated.docx");
    document.body.appendChild(link);
    link.click();
    document.body.appendChild(link);
  } catch (error) {
    console.error("Error in download file:", error);
  }
};

export const getDashFaqApi = async (currentFaqVal) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.dataLens}?persona=${currentFaqVal?.currentColl}&solution=${currentFaqVal?.currentPath}`
    );
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getDocSonarFaq = async (currentFaqVal) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.docSonarFaq}?collection_or_persona=${currentFaqVal?.currentColl}&solution=${currentFaqVal?.currentPath}`
    );
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getCommeFaq = async (currentFaqVal) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.commericalFaq}?collection_or_persona=${currentFaqVal?.currentColl}&solution=${currentFaqVal?.currentPath}`
    );
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getPubMedFaq = async (currentFaqValPubMed) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.pubmedFaq}?solution=${currentFaqValPubMed}`
    );
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getDataVistaFaq = async (currentFaqVal) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.dataVistaFaq}?persona=${currentFaqVal?.currentColl}&solution=${currentFaqVal?.currentPath}`
    );
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getDefaultSentimentData = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.getDefaultSentiments}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error getting data", err);
  }
};

export const getSourceData = async (body) => {
  try {
    let res = await axiosApi.get(`${getUrl.getSource}?user_name=${body}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error getting data", err);
  }
  };

export const SessionDownload = async (userEmail, sessionId, persona_name) => {
  try {
    const res = await axiosApi.get(
      `${getUrl.downloadsession}?email_id=${userEmail}&persona_name=${persona_name}&session_id=${sessionId}`,
      { responseType: "blob" }
    );
    console.log(res);
    return res;
  } catch (err) {
    toast.error("Unable to Download Session, Please try again!!!");
    console.log("Error in Download Session", err);
  }
};

export const getDocumentPage = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.getDocumentList}?user_id=${id}`);
    if (res && res?.data) {
      toast.success("Contents Fetched Successfully !!!");
      return res.data;
    }
  } catch (err) {
    toast.error("Error in getting Contents !!!");
    console.log("Error in getting Contents", err);
  }
};

export const documentArticleBack = async (id) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.documentArticleBack}?content_id=${id}`
    );
    if (res && res?.data) {
      toast.success("Previous Contents Fetched Successfully !!!");
      return res.data;
    }
  } catch (err) {
    console.log("Error in getting Previous Contents", err);
  }
};

export const documentDynamicBannerBack = async (id) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.documentDynamicBannerBack}?content_id=${id}`
    );
    if (res && res?.data) {
      toast.success("Previous Contents Fetched Successfully !!!");
      return res.data;
    }
  } catch (err) {
    console.log("Error in getting Previous Contents", err);
  }
};

export const documentRepTriggerEmailBack = async (id) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.documentRepTriggerEmailBack}?content_id=${id}`
    );
    if (res && res?.data) {
      toast.success("Previous Contents Fetched Successfully !!!");
      return res.data;
    }
  } catch (err) {
    console.log("Error in getting Previous Contents", err);
  }
};

export const documentJournalPaperBack = async (id) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.documentJournalPaperBack}?content_id=${id}`
    );
    if (res && res?.data) {
      toast.success("Previous Contents Fetched Successfully !!!");
      return res.data;
    }
  } catch (err) {
    console.log("Error in getting Previous Contents", err);
  }
};