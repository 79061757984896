import React, { useContext, useState } from "react";
import {
  deleteMedFusion,
  userBot,
  aglogo,
  cancel,
  threeDots,
} from "../../utils/images";
import "./CommonMedFusion.css";
import { MyContext } from "../../context/ContextApi";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchInputMedFusion from "./SearchInputMedFusion";

function Promptpage() {
  const {
    queryResponse,
    query,
    sourceCallIn,
    sourceDataCallIn,
    generating,
    setQueryResponse,
  } = useContext(MyContext);

  const [inputData, setInputData] = useState();
  const [openSourceModal, setOpenSourceModal] = useState(false);

  const staticData = [
    {
      id: 1,
      question: "Show me the interaction Date",
    },
    {
      id: 2,
      question: "What was the follow-up Action items Discussed",
    },
    {
      id: 3,
      question: "What was the Meeting Objective",
    },
    {
      id: 4,
      question: "List the Outcomes and results of the Discussion with HCP",
    },
  ];

  const handleFaqClicked = (event) => {
    setInputData(event.target.textContent);
  };

  const onClickViewSource = () => {
    setOpenSourceModal(true);
  };

  const onClickClearChat = () => {
    if (queryResponse.length > 0) {
      setQueryResponse("");
    }
  };

  const handleCancelModal = () => {
    setOpenSourceModal(false);
  };

  return (
    <div>
      <div className="questAndans-container">
        <div className="questAndans">
          {queryResponse && queryResponse.length > 0 ? (
            <div>
              {queryResponse?.map((val, ind) => (
                <React.Fragment key={ind}>
                  <div className="questions-medFusion">
                    <img src={userBot} alt="circleBot" />
                    <div style={{ marginLeft: "5px" }}>{query[ind]}</div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "10px",
                      }}
                      className="answers-medFusion"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        src={aglogo}
                        alt="circleBot"
                      />
                      <span style={{ marginTop: "10px" }}>{val.response}</span>
                    </div>
                    {sourceCallIn && (
                      <div
                        className="viewSource-medFusion"
                        onClick={onClickViewSource}
                      >
                        View Source
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="static-questions-container">
              <img
                style={{ width: "50px", height: "50px", marginBottom: "20px" }}
                src={aglogo}
                alt="Ag-logo"
              />
              <div className="static-questions">
                {staticData.map((quest, index) => (
                  <div
                    className="faq-quest"
                    key={quest.id}
                    style={{
                      flex: index % 2 === 0 ? "1 1 45%" : "1 1 45%",
                      boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={(e) => handleFaqClicked(e)}
                  >
                    {quest.question}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {generating && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "20px",
            }}
          >
            <span>Generating</span>
            <img
              style={{ width: "20px", height: "20px" }}
              src={threeDots}
              alt="..."
            />
          </div>
        )}

        <div className="clearChat-medFusion" onClick={onClickClearChat}>
          <span>Clear Chat</span>
          <img
            style={{ width: "18px", height: "18px" }}
            src={deleteMedFusion}
            alt="Clear"
          />
        </div>

        <div className="fixed-search-input">
          <SearchInputMedFusion
            inputValue={inputData}
            placeholder={"Enter your Query Here"}
            setInputData={setInputData}
          />
        </div>
      </div>

      {openSourceModal && (
        <Modal
          show={openSourceModal}
          centered
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          }}
        >
          <Modal.Body
            style={{
              textAlign: "center",
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "20px",
              width: "100%",
              maxHeight: "60vh",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Extracted content from source audio
              </Typography>
              <img
                style={{
                  width: "px",
                  height: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                }}
                src={cancel}
                alt="cancel"
                onClick={handleCancelModal}
              />
            </Box>

            <Box
              style={{
                flex: 1,
                overflowY: "auto",
                paddingTop: "10px",
              }}
            >
              <Typography variant="body1" sx={{ mb: 2 }}>
                {sourceDataCallIn}
              </Typography>
            </Box>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default Promptpage;
