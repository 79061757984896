import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { decodedPDF, deleteCollectionFileList } from "../../api/Postaction.js";
import { BsFileEarmarkText } from "react-icons/bs";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { LuExternalLink } from "react-icons/lu";
import { toast } from "react-toastify";
import "./styles.scss";
import { commonStr } from "../../utils/constants/commonStrings.js";
import { MyContext } from "../../context/ContextApi.js";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonalIdentification from "../../components/MlrReview/personalIdentity.js";
import WaterMarkDetection from "../../components/MlrReview/waterMarkDetection.js";
import ClaimsTagTable from "../../components/MlrReview/claimsTagTable.js";
import ReviewBox from "../../components/MlrReview/claimsReview.js";
import LogoTable from "../../components/MlrReview/logoTable.js";

const Sourcebar = ({
  sourceKey,
  currentPage,
  data1,
  fileList,
  collectionName,
  highlightedPdf,
  // onPdfClick,
  orangeFlag,
  redFlag,
  watermarkData,
  // claimsData,
  // piiTextContent,
  // updateHighlightedPdfPage,
}) => {
  const { 
    allValues,
    claimsDataSrc,
    currPageClaims,
    logoDataSrc,
    currPageLogo, 
  } = useContext(MyContext);
  const [files, setFiles] = useState(fileList || []);
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [heightIndex, setHeightIndex] = useState();
  const location = useLocation();

  useEffect(() => {
    setHeightIndex();
  }, [data1]);

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  const handleDeleteFile = async (fileToDelete) => {
    try {
      let body = {
        file_name: fileToDelete,
        collection_name: collectionName,
      };
      await deleteCollectionFileList(body);

      setFiles((prevFileList) =>
        prevFileList.filter((file) => file !== fileToDelete)
      );
    } catch (err) {
      console.error("Error deleting file:", err);
    }
  };

  const handleReadMoreClick = (index) => {
    if (heightIndex === index) {
      setHeightIndex();
    } else {
      setHeightIndex(index);
    }
  };
  const filename = (value) => {
    const segments = value.split("/");
    const fileName = segments[segments.length - 1];
    return fileName;
  };

  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  function base64toBlobPpt(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    });
  }
  function base64toBlobDoc(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  }

  const openPdf = async (element) => {
    console.log(element?.metadata?.source);

    let fileurl = element?.metadata?.source;
    let body = {
      file_name: fileurl,
      collection_name: allValues.collection,
      bucket_name: allValues.bucket,
    };
    let toastID1;
    toastID1 = toast.success("Loading...", {
      autoClose: false,
    });
    let value = await decodedPDF(body);
    toast.dismiss(toastID1);

    if (value !== undefined) {
      if (fileurl.endsWith(".pdf")) {
        let blob = base64toBlob(value);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(`${blobUrl}#page=${element?.metadata?.page + 1}`);
        }
      }
      if (fileurl.endsWith(".pptx")) {
        let blob = base64toBlobPpt(value);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "pptBase64.pptx");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(`${blobUrl}`, "_blank");
        }
      }
      if (fileurl.endsWith(".docx")) {
        let blob = base64toBlobDoc(value);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "docBase64.docx");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, "_blank");
        }
      }
    }
  };

  const expandIcon = isOpen ? "d-block" : "d-none";
  const collapsed = isOpen ? "d-none" : "d-block";

  const path = location.pathname;

  return (
    <div
      className={`source-sidebar ${
        isOpen ? (path ? "expand-mlr" : "expanded") : "collapsed"
      }`}
    >
      <div
        className={`source-sidebar__iconwrap ${
          isOpen ? "spacebt" : "centerbt"
        }`}
      >
        <div className="source-sidebar__iconwrap__icon">
          <BsFileEarmarkText size={20} className={`${expandIcon}`} />
          <span className={`${expandIcon} source-sidebar__iconwrap__title`}>
            &ensp;{commonStr.sources}
          </span>
        </div>

        <div className={`source-sidebar__iconwrap__arrowicon iconHover`}>
          {isOpen ? (
            <TfiArrowCircleRight onClick={toggle} />
          ) : (
            <TfiArrowCircleLeft onClick={toggle} />
          )}
        </div>
      </div>
      <div className={`${collapsed} source-sidebar__fileicon`}>
        <BsFileEarmarkText size={20} />
      </div>
      <div className={`${expandIcon} source-sidebar__list`}>
        {location.pathname === "/contentGeneration" ? (
          <div>
            <ul className="source-sidebar__file-list sourceul">
              {files && files.length > 0 ? (
                files.map((file, index) => (
                  <li key={index} className="sourceli">
                    <div className="d-flex justify-content-between">
                      <div className="sourceFile">{file}</div>
                      <div
                        className="sourceDeleteIcon"
                        onClick={() => handleDeleteFile(file)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="source-sidebar__list__nofiles">No Files</div>
              )}
            </ul>
          </div>
        ) : location.pathname === "/mlrReview" ? (
          highlightedPdf && sourceKey === "pii" ? (
            <div className="source-sidebar__list__box">
              <PersonalIdentification
                orangeFlag={orangeFlag}
                currentPage={currentPage}
                redFlag={redFlag}
              />
              <WaterMarkDetection
                watermarkData={watermarkData}
                currentPage={currentPage}
              />
            </div>
          ) : sourceKey === "claims" ? (
            claimsDataSrc && (
              <div className="source-sidebar__list__box">
                <ReviewBox />
              </div>
            )
          ) : sourceKey === "logo" ? (
            logoDataSrc && (
              <div className="source-sidebar__list__box">
                <LogoTable
                  logoDataSrc={logoDataSrc}
                  currPageLogo={currPageLogo}
                />
              </div>
            )
          ) : (
            <div>No Highlighted Files</div>
          )
        ) : data1 && data1?.source && data1?.source?.length > 0 ? (
          data1?.source?.map((element, i) => {
            return (
              <div key={i + 1} className="source-sidebar__list__box">
                <div className="source-sidebar__list__numbers">
                  {i + 1}.
                  <div className="source-sidebar__list__numbers__icons">
                    <div>{filename(element?.metadata?.source)}</div>
                    <div>
                      <LuExternalLink
                        size={20}
                        onClick={() => openPdf(element)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`source-sidebar__list__content mt-2 ${
                    heightIndex === i ? "autoheight" : "standardheight"
                  }`}
                >
                  {element?.page_content}
                </div>
                <p
                  onClick={() => handleReadMoreClick(i)}
                  className="source-sidebar__list__readmore iconHover"
                >
                  {heightIndex === i ? "...Read Less" : "Read More..."}
                </p>
              </div>
            );
          })
        ) : (
          <div className="source-sidebar__list__nofiles">
            {commonStr.noSourceFile}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sourcebar;
