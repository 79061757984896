import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import "./index.css";
import { toast } from "react-toastify";
import {
  columnDataForMedPulse,
  translateColumnData,
} from "../../api/Postaction";
import { FormControl, MenuItem, Select } from "@mui/material";
import { GoSearch } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";

const MedLingo = () => {
  const { Column, HeaderCell, Cell } = Table;
  const {
    tableHeading,
    setIsMedPulseData,
    isFilePath,
    setMedPulse,
    setStoredFileName,
  } = useContext(MyContext);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [records, setRecords] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [translationResult, setTranslationResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [expSearch, setExpSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  // const startIndex = limit * (page - 1);

  const handleTranslate = async () => {
    let body = {
      column_name: selectedColumn,
      recently_uploaded_file_path: isFilePath.recent_file_path,
      uploaded_file_name: isFilePath.file_name,
    };
    try {
      if (selectedColumn) {
        setLoading(true);
        const translationResult = await translateColumnData(body);

        if (translationResult && translationResult.status === 200) {
          setLoading(false);
          // console.log(
          //   "datas",
          //   JSON.stringify(translationResult.data?.lingo_output)
          // );
          setTranslationResult(translationResult.data?.lingo_output);
          // setSelectedColumn("");
          // setIsMedPulseData({});
          setStoredFileName(translationResult.data?.stored_file);
          // let body = {
          //   stored_file_name: translationResult.data?.stored_file,
          // };
          // const data = await columnDataForMedPulse(body);
          // if (data) {
          //   setIsMedPulseData(data);
          // }
        }
      } else {
        toast.error("No column selected.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error translating column data");
    } finally {
      setLoading(false);
      // Set loading to false when API request ends
    }
  };

  const handleChange = (event) => {
    setSelectedColumn(event.target.value);
    setSearchQuery("");
  };

  const toggleExpSearch = () => {
    setExpSearch((prevExpSearch) => !prevExpSearch);
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      return translationResult;
    }
    return translationResult?.filter((item) => {
      return Object.values(item).some((val) =>
        val.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  useEffect(() => {
    if (translationResult) {
      const filteredData = filterData();
      const start = limit * (page - 1);
      const end = start + limit;
      const slicedData = filteredData?.slice(start, end);
      setRecords(slicedData);
    }
  }, [translationResult, page, limit, searchQuery]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1); // Reset page when limit changes
  };

  if (records.length > 0) {
    setMedPulse(true);
  }

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex justify-content-center my-5">
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h5 className="d-flex mt-3 mb-2">Med Lingo</h5>
                  <p className="content-lingo">
                    Med Lingo translates complex medical jargon into clear,
                    understandable language
                  </p>
                  <div className="row">
                    <label className="midLingoLabel">
                      Select the appropriate field
                    </label>
                    <div className="col-10">
                      <FormControl fullWidth>
                        <Select
                          value={selectedColumn}
                          onChange={handleChange}
                          className="medLingo_select mt-1"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>Choose the value</em>
                          </MenuItem>
                          {tableHeading?.map((column, index) => (
                            <MenuItem key={index} value={column}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-2 p-0">
                      <button
                        className="btn-sm btn-light btnLight float-right"
                        type="button"
                        onClick={handleTranslate}
                      >
                        Translate
                      </button>
                    </div>
                    {loading ? (
                      <div className="w-100 mt-5">
                        <CommonLoader />
                      </div>
                    ) : (
                      records.length > 0 && (
                        <>
                          <div className="col-12 mt-3 p-0">
                            <div className="searchContainer">
                              <input
                                type="search"
                                className={expSearch && "exp-search-show"}
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                              />
                              <button
                                type="button"
                                className="btnSearch"
                                onClick={toggleExpSearch}
                              >
                                {expSearch ? <IoMdClose /> : <GoSearch />}
                              </button>
                            </div>
                          </div>
                          <div className="col-12 p-1">
                            <Table
                              className="custom-table"
                              virtualized
                              wordWrap="break-word"
                              hover={false}
                              height={400}
                              data={records}
                              style={{
                                scrollbarWidth: "0",
                                maxHeight: "50vh",
                                border: "1px solid #4C85F7",
                              }}
                              align="center"
                            >
                              {[...Object.keys(records[0])].map(
                                (key, index) => (
                                  <Column
                                    flexGrow={
                                      key === "S.No"
                                        ? 1
                                        : key.toLowerCase().includes("comments")
                                        ? 3
                                        : 2
                                    }
                                    verticalAlign="middle"
                                    key={key}
                                  >
                                    <HeaderCell>{key}</HeaderCell>
                                    <Cell dataKey={key}>
                                      {(rowData, rowIndex) => {
                                        // if (key === "S.No") {
                                        //   return startIndex + rowIndex + 1; // Render serial number
                                        // }
                                        return rowData[key];
                                      }}
                                    </Cell>
                                  </Column>
                                )
                              )}
                            </Table>
                            <div style={{ paddingTop: 20 }}>
                              <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={[
                                  "total",
                                  "-",
                                  "limit",
                                  "|",
                                  "pager",
                                  "skip",
                                ]}
                                total={translationResult.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={handlePageChange}
                                onChangeLimit={handleLimitChange}
                              />
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedLingo;