import React, { useContext, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { FiShare } from "react-icons/fi";
import { HiThumbUp, HiThumbDown } from "react-icons/hi";
import { circleBot, userBot } from "../../utils/images";
import ThreeDotSpinner from "../threeDotSpinner";
import CommonModal from "../commonModal";
import { commonStr } from "../../utils/constants/commonStrings";
import { getEvaluateApi } from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import { useLocation } from "react-router";

function CommonPromChat({
  indexValue,
  user,
  AI,
  audio,
  thumbsUp,
  thumbsDown,
  onThumbsUpClick,
  onThumbsDownClick,
  onShareClick,
  onShareNumberClick,
  isViewSource = false,
  onClickViewSource,
  isDashboardSplit = false,
  audioVisble = true,
  evaluateShow = false,
  listData,
  updateEvaluateData = {},
  hidelike = true,
  hidePayload = 1,
  isShowDetective = false,
  hideInfoDetective = false,
}) {
  const { allValues } = useContext(MyContext);
  const [evaluateModal, setEvaluateModal] = useState(false);
  const [dataDetectiveModal, setDataDetetectiveModal] = useState(false);

  const [evaluateData, setEvaluateData] = useState([]);
  const [dataDetectiveList, setDataDetectiveList] = useState([]);

  const location = useLocation();
  const currentPath = location.pathname;

  const handleClickInfoIcon = () => {
    if (listData?.evaluate) {
      setEvaluateData(listData?.evaluate);
      updateEvaluateData(listData?.evaluate);
      setEvaluateModal(true);
    } else {
      handleEvaluateApi();
    }
  };

  const handleEvaluateApi = async () => {
    let body = {};

    if (hidePayload === 1) {
      body = {
        result: AI,
        collection_name: allValues.collection,
      };
    }
    if (hidePayload === 2) {
      body = {
        user_response: AI,
      };
    }
    if (hidePayload === 3) {
      body = {
        output: AI,
      };
    }

    let evaluateResponse = await getEvaluateApi(body, hidePayload);
    if (evaluateResponse) {
      updateEvaluateData(evaluateResponse);
      setEvaluateData(evaluateResponse);
      setEvaluateModal(true);
    }
  };

  const handleDataModal = () => {
    setDataDetectiveList(listData?.steps);
    setDataDetetectiveModal(true);
  };

  return (
    <div>
      <div className="questions">
        <img src={userBot} alt="circleBot" />
        &ensp;&ensp;
        <div>{user}</div>
      </div>
      <div className="answers">
        <div className="ml-1">
          <img src={circleBot} alt="circleBot" />
        </div>
        &ensp;
        <div className="sentences">
          {isDashboardSplit
            ? AI?.split("\n").map((paragraph, pIndex) => (
                <p key={pIndex}>{paragraph}</p>
              ))
            : AI}

          {isShowDetective && (
            <div className="thumbs-icons mt-2">
              <div
                className={`thumbs-icon ${thumbsUp === true ? "active" : ""}`}
                onClick={onThumbsUpClick} // TODO
              >
                <HiThumbUp />
              </div>
              <div
                className={`thumbs-icon ${
                  thumbsDown === true ? "activedown" : ""
                }`}
                onClick={onThumbsDownClick} // TODO
              >
                <HiThumbDown />
              </div>
              {hideInfoDetective && (
                <div className={`thumbs-icon`}>
                  <IoMdInformationCircle
                    className="iconHover"
                    size={25}
                    color="black"
                    onClick={handleDataModal}
                  />
                </div>
              )}
            </div>
          )}


          {!currentPath.includes("salesRepoCopilot") && audioVisble && (
            <div className="audioplayer">
              {audio[indexValue] !== undefined ? (
                <>
                  <audio controls style={{ maxWidth: "100%" }}>
                    <source src={audio[indexValue].audio} type="audio/mp3" />
                  </audio>
                  <div className="dropdown d-flex">
                    <button
                      type="button"
                      id="dropdownMenu1"
                      data-toggle="dropdown"
                    >
                      <FiShare size={20} color="#ea356f" />
                    </button>
                    {evaluateShow && (
                      <IoMdInformationCircle
                        className="iconHover"
                        size={25}
                        color="#ea356f"
                        onClick={handleClickInfoIcon}
                      />
                    )}

                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenu1"
                    >
                      <li>
                        <p
                          className="shareOptions"
                          onClick={onShareNumberClick}
                        >
                          WhatsApp
                        </p>
                      </li>
                      <li>
                        <p className="shareOptions" onClick={onShareClick}>
                          Outlook
                        </p>
                      </li>
                      <li>
                        <p className="shareOptions">Teams</p>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <ThreeDotSpinner />
              )}
            </div>
          )}

          {isViewSource && (
            <div className="viewSource" onClick={onClickViewSource}>
              View Source
            </div>
          )}
        </div>
        {hidelike && (
          <div className="thumbs-icons">
            <div
              className={`thumbs-icon ${thumbsUp === true ? "active" : ""}`}
              onClick={onThumbsUpClick} // TODO
            >
              <HiThumbUp />
            </div>
            <div
              className={`thumbs-icon ${
                thumbsDown === true ? "activedown" : ""
              }`}
              onClick={onThumbsDownClick} // TODO
            >
              <HiThumbDown />
            </div>
          </div>
        )}
      </div>

      {evaluateModal && (
        <CommonModal
          showModal={evaluateModal}
          title={"Evaluate"}
          content={"Evalute"}
          onCancel={() => setEvaluateModal(false)}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          evaluateData={evaluateData}
          evaluateShow={evaluateShow}
        />
      )}

      {dataDetectiveModal && (
        <CommonModal
          showModal={dataDetectiveModal}
          title={"Chain Of Thoughts"}
          onCancel={() => setDataDetetectiveModal(false)}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          dataDetShow
          dataList={dataDetectiveList}
        />
      )}
    </div>
  );
}

export default CommonPromChat;
