import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaAlignLeft } from "react-icons/fa6";
import { FaAlignRight } from "react-icons/fa6";
import { FaAlignCenter } from "react-icons/fa6";
import { documentStructureArticleHeadngs } from "../../api/Postaction";
import { documentArticleBack } from "../../api/GetActions";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { MyContext } from "../../context/ContextApi";
import { AnimationDocument } from "../../utils/images/index.js";
import { PreloaderDocument } from "../../utils/images/index.js";
import { MdOutlinePostAdd } from "react-icons/md";
import "./documentHeading.css";

const DocumentHeading = () => {
  const {
    structureArticle,
    setStructureHeading,
    contenttype,
    method,
    backButton,
    structurecontentID,
    setStructureContentID,
    setBackButton,
  } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const payload = location.state;
  const [headings, setHeadings] = useState([]);
  const [title, setTitle] = useState("");
  const [titleHeading, setTitleHeading] = useState("No");

  useEffect(() => {
    const fetchBackArticleData = async () => {
      try {
        const response = await documentArticleBack(structureArticle.content_id);
        if (response) {
          setStructureContentID(response.content_id);
          setTitle(response.title);

          const mappedHeadings = response.key_headings.map((heading) => ({
            id: heading.heading_id,
            type: heading.heading_type,
            value: heading.heading_value,
            alignment: heading.heading_alignment || "left",
            subHeadings:
              heading.sub_headings?.map((sub) => ({
                id: sub.heading_id,
                value: sub.heading_value,
                alignment: sub.heading_alignment || "left",
              })) || [],
          }));

          setHeadings(mappedHeadings);
        }
      } catch (error) {
        console.error("Error fetching back article data:", error);
        toast.error("Failed to fetch article data.");
      }
    };

    if (backButton === "back") {
      fetchBackArticleData();
    } else if (structureArticle) {
      setStructureContentID(structureArticle.content_id);
      setTitle(structureArticle.title);

      const mappedHeadings = structureArticle.key_headings.map((heading) => ({
        id: heading.heading_id,
        type: heading.heading_type,
        value: heading.heading_value,
        alignment: heading.heading_alignment || "left",
        subHeadings:
          heading.sub_headings?.map((sub) => ({
            id: sub.heading_id,
            value: sub.heading_value,
            alignment: sub.heading_alignment || "left",
          })) || [],
      }));

      setHeadings(mappedHeadings);
    }
  }, [structureArticle, backButton]);

  const updateRadioHeading = (value) => {
    setTitleHeading(value);
  };

  const updateTitleHeading = (value) => {
    setTitle(value);
  };

  const setAlignment = (id, alignment, parentId = null) => {
    if (parentId) {
      setHeadings((prev) =>
        prev.map((heading) =>
          heading.id === parentId
            ? {
                ...heading,
                subHeadings: heading.subHeadings.map((sub) =>
                  sub.id === id ? { ...sub, alignment } : sub
                ),
              }
            : heading
        )
      );
    } else {
      setHeadings((prev) =>
        prev.map((heading) =>
          heading.id === id ? { ...heading, alignment } : heading
        )
      );
    }
  };

  const addSubHeading = (id) => {
    setHeadings((prev) =>
      prev.map((heading) =>
        heading.id === id
          ? {
              ...heading,
              subHeadings: [
                ...heading.subHeadings,
                { id: `${id}-${heading.subHeadings.length + 1}`, value: "" },
              ],
            }
          : heading
      )
    );
  };

  const deleteHeading = (id, parentId = null) => {
    if (parentId) {
      setHeadings((prev) =>
        prev.map((heading) =>
          heading.id === parentId
            ? {
                ...heading,
                subHeadings: heading.subHeadings.filter((sub) => sub.id !== id),
              }
            : heading
        )
      );
    } else {
      setHeadings((prev) => {
        const updatedHeadings = prev.filter((heading) => heading.id !== id);
        
        return updatedHeadings.map((heading, index) => ({
          ...heading,
          type: `H${index + 1}`, 
          subHeadings: heading.subHeadings.map((sub, subIndex) => ({
            ...sub,
            type: `H${index + 2}`, 
          })),
        }));
      });
    }
  };

  const updateHeading = (id, value, parentId = null) => {
    if (parentId) {
      setHeadings((prev) =>
        prev.map((heading) =>
          heading.id === parentId
            ? {
                ...heading,
                subHeadings: heading.subHeadings.map((sub) =>
                  sub.id === id ? { ...sub, value } : sub
                ),
              }
            : heading
        )
      );
    } else {
      setHeadings((prev) =>
        prev.map((heading) =>
          heading.id === id ? { ...heading, value } : heading
        )
      );
    }
  };

  const addNewHeading = () => {
    const currentMaxHeadingNumber = Math.max(
      ...headings.map((heading) => parseInt(heading.type.replace("H", ""), 10))
    );

    const nextHeadingType = `H${currentMaxHeadingNumber + 1}`;

    setHeadings((prev) => [
      ...prev,
      {
        id: headings.length + 1,
        type: nextHeadingType,
        value: "",
        subHeadings: [],
      },
    ]);
  };

  const handleGenerate = async () => {
    setLoading(true);
    const payload = {
      content_id: structurecontentID,
      title: title,
      include_citation: titleHeading,
      key_heading: headings.map((heading) => ({
        heading_type: heading.type,
        heading_value: heading.value,
        heading_id: heading.id,
        heading_alignment: heading.alignment || "left",
        sub_headings: heading.subHeadings.map((sub) => ({
          heading_value: sub.value,
          heading_alignment: sub.alignment || "left",
          heading_id: sub.id,
        })),
      })),
    };

    try {
      const response = await documentStructureArticleHeadngs(payload);
      setStructureHeading(response);
      navigate("/documentEditor");
    } catch (error) {
      console.error("Error generating document:", error);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return;

    const reorderedHeadings = Array.from(headings);
    const [movedHeading] = reorderedHeadings.splice(source.index, 1);
    reorderedHeadings.splice(destination.index, 0, movedHeading);

    const adjustedHeadings = reorderedHeadings.map((heading, index) => {
      const newHeadingType = `H${index + 1}`;
      const adjustedSubHeadings = heading.subHeadings.map((sub, subIndex) => {
        const newSubHeadingType = `H${index + 2}`;
        return { ...sub, type: newSubHeadingType };
      });

      return {
        ...heading,
        type: newHeadingType,
        subHeadings: adjustedSubHeadings,
      };
    });

    setHeadings(adjustedHeadings);
  };

  useEffect(() => {
    if (loading) {
    }
  }, [loading]);

  return (
    <div className="content-heading-page">
      {loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={AnimationDocument}
              alt="Loading animation"
              className="loading-heading-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Processing, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      <button
        type="button"
        className={`back-buttonHeading ${loading ? "dim-content" : ""}`}
        onClick={() => {
          setBackButton("back");
          navigate(`/documentStructure?content=${contenttype}`);
        }}
      >
        ← Back
      </button>
      <div className={`contentHeading ${loading ? "dim-content" : ""}`}>
        <h3>Content Structure</h3>
      </div>

      <div className={`title-inputHeading ${loading ? "dim-content" : ""}`}>
        <label htmlFor="title">Title:</label>
        <input
          id="title"
          type="text"
          value={title}
          className="structure-input"
          onChange={(e) => updateTitleHeading(e.target.value)}
        />
        {method !== "AI Assistant" && (
          <div className="include-citations">
            <span>Include Citations:</span>
            <div className="include-citations-radio">
              <label>Yes</label>
              <input
                type="radio"
                name="includeCitations"
                value="Yes"
                onChange={(e) => updateRadioHeading(e.target.value)}
              />
              <label>No</label>
              <input
                type="radio"
                name="includeCitations"
                checked={titleHeading === "No"}
                value="No"
                onChange={(e) => updateRadioHeading(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>

      <div className={`table-heading ${loading ? "dim-content" : ""}`}>
        <h3>Table of Contents</h3>
      </div>

      {/* Table-of-contents without drag & drop  */}

      {/* <div className="table-of-contents">
        {headings.map((heading) => (
          <div key={heading.id} className="heading-container">
            <div className="heading-row">
              <label>{heading.type}: </label>
              <input
                type="text"
                value={heading.value}
                onChange={(e) => updateHeading(heading.id, e.target.value)}
                placeholder="Enter The New Heading"
                className={`heading-input ${heading.alignment}`}
              />
              <button
                className="add-button"
                onClick={() => addSubHeading(heading.id)}
              >
                +
              </button>
              <button
                className="delete-button"
                onClick={() => deleteHeading(heading.id)}
              >
                -
              </button>
              <div
                className="alignicons"
                onClick={() => setAlignment(heading.id, "left")}
              >
                <FaAlignLeft />
              </div>
              <div
                className="alignicons"
                onClick={() => setAlignment(heading.id, "center")}
              >
                <FaAlignCenter />
              </div>
              <div
                className="alignicons"
                onClick={() => setAlignment(heading.id, "right")}
              >
                <FaAlignRight />
              </div>
            </div>

            {heading.subHeadings.length > 0 && (
              <div className="subheading-container">
                {heading.subHeadings.map((sub, index) => (
                  <div key={sub.id} className="subheading-row">
                    <label>{`${heading.type}.${index + 1}:`}</label>
                    <input
                      type="text"
                      value={sub.value}
                      placeholder="Enter the SubHeading"
                      onChange={(e) =>
                        updateHeading(sub.id, e.target.value, heading.id)
                      }
                      className={`subheading-input ${sub.alignment}`}
                    />
                    <button
                      className="delete-button"
                      onClick={() => deleteHeading(sub.id, heading.id)}
                    >
                      -
                    </button>
                    <div
                      className="alignicons"
                      onClick={() => setAlignment(sub.id, "left", heading.id)}
                    >
                      <FaAlignLeft />
                    </div>
                    <div
                      className="alignicons"
                      onClick={() => setAlignment(sub.id, "center", heading.id)}
                    >
                      <FaAlignCenter />
                    </div>
                    <div
                      className="alignicons"
                      onClick={() => setAlignment(sub.id, "right", heading.id)}
                    >
                      <FaAlignRight />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="headings" type="list">
          {(provided, snapshot) => (
            <div
              className={`table-of-contents ${loading ? "dim-content" : ""} ${
                snapshot.isDraggingOver ? "highlight" : ""
              }`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {headings.map((heading, index) => (
                <Draggable
                  key={heading.id}
                  draggableId={String(heading.id)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="heading-container"
                    >
                      <div className="heading-row">
                        <label>{heading.type}: </label>
                        <input
                          type="text"
                          value={heading.value}
                          onChange={(e) =>
                            updateHeading(heading.id, e.target.value)
                          }
                          placeholder="Enter The New Heading"
                          className={`heading-input ${heading.alignment}`}
                        />
                        <button
                          className="add-button"
                          onClick={() => addSubHeading(heading.id)}
                        >
                          +
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => deleteHeading(heading.id)}
                        >
                          -
                        </button>
                        <div
                          className="alignicons"
                          onClick={() => setAlignment(heading.id, "left")}
                        >
                          <FaAlignLeft />
                        </div>
                        <div
                          className="alignicons"
                          onClick={() => setAlignment(heading.id, "center")}
                        >
                          <FaAlignCenter />
                        </div>
                        <div
                          className="alignicons"
                          onClick={() => setAlignment(heading.id, "right")}
                        >
                          <FaAlignRight />
                        </div>
                      </div>

                      {heading.subHeadings.length > 0 && (
                        <Droppable droppableId={String(heading.id)} type="list">
                          {(provided) => (
                            <div
                              className="subheading-container"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {heading.subHeadings.map((sub, subIndex) => (
                                <Draggable
                                  key={sub.id}
                                  draggableId={String(sub.id)}
                                  index={subIndex}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="subheading-row"
                                    >
                                      <label>{`${heading.type}.${
                                        subIndex + 1
                                      }:`}</label>
                                      <input
                                        type="text"
                                        value={sub.value}
                                        placeholder="Enter the Subheading"
                                        onChange={(e) =>
                                          updateHeading(
                                            sub.id,
                                            e.target.value,
                                            heading.id
                                          )
                                        }
                                        className={`subheading-input ${sub.alignment}`}
                                      />
                                      <button
                                        className="delete-button"
                                        onClick={() =>
                                          deleteHeading(sub.id, heading.id)
                                        }
                                      >
                                        -
                                      </button>
                                      <div
                                        className="alignicons"
                                        onClick={() =>
                                          setAlignment(
                                            sub.id,
                                            "left",
                                            heading.id
                                          )
                                        }
                                      >
                                        <FaAlignLeft />
                                      </div>
                                      <div
                                        className="alignicons"
                                        onClick={() =>
                                          setAlignment(
                                            sub.id,
                                            "center",
                                            heading.id
                                          )
                                        }
                                      >
                                        <FaAlignCenter />
                                      </div>
                                      <div
                                        className="alignicons"
                                        onClick={() =>
                                          setAlignment(
                                            sub.id,
                                            "right",
                                            heading.id
                                          )
                                        }
                                      >
                                        <FaAlignRight />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <>
        <label className={`upload-label ${loading ? "dim-content" : ""} `}>
          <span className="browse-heading-button" onClick={addNewHeading}>
            {" "}
            <MdOutlinePostAdd className="browser-heading-icon" /> Add New
            Heading
          </span>
        </label>
      </>
      <div className={`form-actions ${loading ? "dim-content" : ""}`}>
        <button
          type="button"
          className="cancel-button"
          onClick={() => navigate("/documentStructure")}
        >
          Cancel
        </button>

        <button
          className="generate-button"
          type="button"
          onClick={handleGenerate}
        >
          Generate Content
        </button>
      </div>
    </div>
  );
};

export default DocumentHeading;
