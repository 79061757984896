import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { zoomdecodedApi } from "../../api/Postaction.js";
import { BsFileEarmarkText } from "react-icons/bs";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { toast } from "react-toastify";
import "./styles.scss";
import { commonStr } from "../../utils/constants/commonStrings.js";
import { MyContext } from "../../context/ContextApi.js";
import VideModal from "../videoModal/index.js";

const ZoomSourceBar = ({ data1, fileList, collectionName, highlightedPdf }) => {
  const { zoomCollValue } = useContext(MyContext);
  const [files, setFiles] = useState(fileList || []);
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [heightIndex, setHeightIndex] = useState();

  const [isVideoModal, setIsVideoModal] = useState(false);

  const [isVideoUrl, setIsVideoUrl] = useState("");
  const [isVideoStart, setIsVideoStart] = useState("");
  const [isVideoEnd, setIsVideoEnd] = useState("");
  const [isVideoTitle, setIsVideoTitle] = useState("");

  useEffect(() => {
    setHeightIndex();
  }, [data1]);

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  const handleReadMoreClick = (index) => {
    if (heightIndex === index) {
      setHeightIndex();
    } else {
      setHeightIndex(index);
    }
  };

  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  function base64toBlobPpt(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    });
  }
  function base64toBlobDoc(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  }

  const handleOpenVideo = async (element, type) => {
    let fileurl = element?.metadata?.sources;
    let body = {
      file_name: fileurl,
      collection_name: zoomCollValue,
      bucket_name: "zoomdocsonar",
    };
    let toastID1;
    toastID1 = toast.success("Loading...", {
      autoClose: false,
    });

    let videoResult = await zoomdecodedApi(body);
    if (videoResult) {
      setIsVideoUrl(videoResult);

      if (type === "start") {
        setIsVideoStart(Number(element?.metadata?.start_time));
      } else {
        setIsVideoStart(Number(element?.metadata?.end_time));
      }
      setIsVideoTitle(element?.metadata?.source);
      toast.dismiss(toastID1);

      handleClicStartTime();
    } else {
      toast.dismiss(toastID1);
    }
  };

  const handleClicStartTime = (val) => {
    setIsVideoModal(true);
  };

  const handleCloseModal = () => {
    setIsVideoModal(false);
    // setIsVideoUrl(null);
    // setIsVideoStart(null);
    // setIsVideoEnd(null);
  };

  const expandIcon = isOpen ? "d-block" : "d-none";
  const collapsed = isOpen ? "d-none" : "d-block";

  return (
    <div className={`source-sidebar ${isOpen ? "expanded" : "collapsed"}`}>
      <div
        className={`source-sidebar__iconwrap ${
          isOpen ? "spacebt" : "centerbt"
        }`}
      >
        <div className="source-sidebar__iconwrap__icon">
          <BsFileEarmarkText size={20} className={`${expandIcon}`} />
          <span className={`${expandIcon} source-sidebar__iconwrap__title`}>
            &ensp;{commonStr.sources}
          </span>
        </div>

        <div className={`source-sidebar__iconwrap__arrowicon iconHover`}>
          {isOpen ? (
            <TfiArrowCircleRight onClick={toggle} />
          ) : (
            <TfiArrowCircleLeft onClick={toggle} />
          )}
        </div>
      </div>
      <div className={`${collapsed} source-sidebar__fileicon`}>
        <BsFileEarmarkText size={20} />
      </div>
      <div className={`${expandIcon} source-sidebar__list`}>
        {data1 && data1?.source && data1?.source?.length > 0 ? (
          data1?.source?.map((element, i) => {
            return (
              <div key={i + 1} className="source-sidebar__list__box">
                <div
                  className="source-sidebar__list__numbers"
                  style={{ fontWeight: "bold" }}
                >
                  {i + 1}. {element?.metadata?.sources}
                </div>
                <div
                  className={`source-sidebar__list__content mt-2 ${
                    heightIndex === i ? "autoheight" : "standardheight"
                  }`}
                >
                  {element?.page_content}
                </div>

                <p
                  className="source-sidebar__list__time iconHover"
                  onClick={() => handleOpenVideo(element, "start")}
                >
                  <b>Start Time : </b>
                  {element?.metadata?.start_time}
                </p>
                <p
                  onClick={() => handleOpenVideo(element, "end")}
                  className="source-sidebar__list__time iconHover"
                >
                  <b>End Time : </b>
                  {element?.metadata?.end_time}
                </p>
                <p
                  onClick={() => handleReadMoreClick(i)}
                  className="source-sidebar__list__readmore iconHover"
                >
                  {heightIndex === i ? "...Read Less" : "Read More..."}
                </p>
              </div>
            );
          })
        ) : (
          <div className="source-sidebar__list__nofiles">
            {commonStr.noSourceFile}
          </div>
        )}
      </div>

      {isVideoModal && isVideoUrl && (
        <VideModal
          showModal={isVideoModal}
          title={"Video "}
          onCancel={() => handleCloseModal()}
          onCancelTitle={commonStr.close}
          confirmHide={false}
          videoUrl={isVideoUrl}
          startTime={isVideoStart}
          endTime={isVideoEnd}
          isVideoTitle={isVideoTitle}
        />
      )}
    </div>
  );
};

export default ZoomSourceBar;
