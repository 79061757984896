import { string, z } from "zod";
import { isValid, parseISO } from "date-fns";

export const personaValidation = z.object({
  Role: string()
    .min(2, { message: "Persona is Required" })
    .regex(/^(?:[A-Z_][a-zA-Z0-9_-]*|[a-z][a-zA-Z0-9_-]*)$/, {
      message: "Name must be either Uppercase or Pascal case",
    }),
  TableAccess: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  SolutionAccess: z.object({
    value: z.string(),
    label: z.string(),
  }),
  kpi: string().optional(),
});

export const EditPersonaaValidation = z.object({
  editRole: string().min(2, { message: "Persona is Required" }),
  editTableAccess: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  kpi: string().optional(),
});

export const userValidation = z.object({
  Persona: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  Firstname: string().min(3, { message: "FirstName  is Required" }),
  Lastname: string().min(1, { message: "LastName is Required" }),
  Email: string().min(3, { message: "Email is Required" }),
  //Password: string().min(3, { message: "Password is Required" }),
  Collection: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  Solution: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  isAdmin: string({
    required_error: "AdminAccess is required",
    invalid_type_error: "AdminAccess must be a string",
  }),
});

export const EdituserValidation = z.object({
  Persona: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  Firstname: string().min(3, { message: "FirstName  is Required" }),
  Lastname: string().min(1, { message: "LastName is Required" }),
  Email: string().min(3, { message: "Email is Required" }),
  //Password: string().min(3, { message: "Password is Required" }),
  Collection: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  Solution: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  isAdmin: string({
    required_error: "AdminAccess is required",
    invalid_type_error: "AdminAccess must be a string",
  }),
});

// export const schedulerdate = z.object({
//   Persona: string({
//     required_error: "Persona is required",
//     invalid_type_error: "Persona must be a string",
//   }),
//   Date: z
//     .string()
//     .refine((value) => isValid(parseISO(value)), "Invalid date format"),
//   Time: z.string().regex(/^\d{2}:\d{2}$/),
//   Recurrence: string({
//     required_error: "Recurrence is required",
//     invalid_type_error: "Recurrence must be a string",
//   }),
//   Data: string().min(3, { message: "Data is Required" }),
//   SelectDay: string({
//     required_error: "Day is required",
//     invalid_type_error: "Day must be a string",
//   }),
// });

export const schedulerday = z.object({
  Persona: string({
    required_error: "Persona is required",
    invalid_type_error: "Persona must be a string",
  }),
  Recurrence: string({
    required_error: "Recurrence is required",
    invalid_type_error: "Recurrence must be a string",
  }),
  Time: z.string().regex(/^\d{2}:\d{2}$/),
  SelectDay: string({
    required_error: "Day is required",
    invalid_type_error: "Day must be a string",
  }),
  Endtime: string().min(3, { message: "EndTime is Required" }).optional(),
  Date: z
    .string()
    .refine((value) => isValid(parseISO(value)), "Invalid date format")
    .optional(),
});

export const createCollectionValidaton = z.object({
  collectionname: string()
    .min(2, { message: "Collection Name is Required" })
    .regex(/^(?:[A-Z]+|[a-z][a-zA-Z0-9]*)$/, {
      message: "Name must be either Uppercase or Pascal case",
    }),
  bucketname: string().min(2, { message: "Bucket Name is Required" }),
  SolutionAccess: z.object({
    value: z.string(),
    label: z.string(),
  }),
  // folder: string().optional(),
});
export const editCollectionValidaton = z.object({
  collectionname: string()
    .min(2, { message: "Collection Name is Required" })
    .regex(/^(?:[A-Z]+|[a-z][a-zA-Z0-9]*)$/, {
      message: "Name must be either Uppercase or Pascal case",
    }),
  bucketname: string().min(2, { message: "Bucket Name is Required" }),
  // folder: string().optional(),
});

export const dashboardDetails = z.object({
  AccountID: string().min(2, { message: "Account id is Required" }).optional(),
  DashboardID: string({
    required_error: "Select Dashboard",
    invalid_type_error: "Select Dashboard",
  }).optional(),
  Region: string({
    required_error: "Select Region",
    invalid_type_error: "Select Region",
  }).optional(),
});

export const workBookValidation = z.object({
  ProjectList: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  WorkBook: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .min(1, { message: "Select Atleast one option" }),
  kpi: string().optional(),
});
