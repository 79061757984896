//import axios from "axios";
//import axiosApi from "../Interceptor/axiosInterceptor";
import axiosApi from "../interceptors/axiosinterceptor";
import { deleteUrl, getUrl, postUrl, putUrl } from "./Endpoint";
import { toast } from "react-toastify";

export const creatPersona = async (ele, setFetch) => {
  let a = toast.warning("Persona Creating...", { autoClose: false });
  try {
    let body = {
      persona_name: ele.Role,
      solution_name: ele.SolutionAccess,
      tables: ele.TableAccess,
      kpi: ele.kpi,
    };
    if (ele.kpi[0] === "") {
      delete body.kpi;
    }
    let response = await axiosApi.post(`${postUrl.createPersona}`, body);
    toast.dismiss(a);
    toast.success("Persona Created Successfully!!!");
    setFetch((current) => !current);
    return response;
  } catch (err) {
    toast.dismiss(a);
    if (err.response.status === 500) {
      toast.error("Persona Name Already Exists");
    }
  }
};

export const updatePersona = async (ele, setFetch, updatePersona) => {
  let a = toast.warning("Updating Persona...", { autoClose: false });
  let body = {
    persona_name: ele.editRole,
    tables: ele.editTableAccess,
    kpi: ele.kpi,
  };
  try {
    let response = await axiosApi.put(
      `${putUrl.putPersona}${updatePersona}/`,
      body
    );
    toast.dismiss(a);
    toast.success(`${ele.editRole}"Updated Successfully!!!"`);
    setFetch((current) => !current);
  } catch (err) {
    toast.dismiss(a);
    console.log("Error in Updating Persona", err);
  }
};

export const deletePersona = async (ele, setFetch) => {
  let a = toast.warning("Deleting Persona...", { autoClose: false });
  try {
    let response = await axiosApi.delete(
      `${deleteUrl.deletePersona}${ele.Persona}/`
    );
    toast.dismiss(a);
    toast.success(`${ele.Persona} "Deleted Successfully!!!"`);
    setFetch((current) => !current);
  } catch (err) {
    toast.dismiss(a);
    toast.error(`${err.response.data.detail}  `, {
      closeOnClick: true,
    });
  }
};

export const creatUser = async (ele, setFetch) => {
  let a = toast.warning("User Creating...", { autoClose: false });
  try {
    let body = {
      email: ele.Email,
      password: ele.Password,
      firstname: ele.Firstname,
      lastname: ele.Lastname,
      persona_name: ele.Persona,
      collection_name: ele.Collection,
      solution_name: ele.Solution,
      is_admin: ele.isAdmin === "False" ? false : true,
    };
    let response = await axiosApi.post(`${postUrl.createUser}`, body);
    toast.dismiss(a);
    toast.success("User Created Successfully!!!");
    setFetch((current) => !current);
    return response;
  } catch (err) {
    toast.dismiss(a);
    console.log("Error in creating User", err);
  }
};

export const updateUser = async (ele, setFetch, email) => {
  let a = toast.warning("User Updating...", { autoClose: false });
  let body = {
    email: ele.Email,
    password: ele.Password,
    firstname: ele.Firstname,
    lastname: ele.Lastname,
    persona_name: ele.Persona,
    collection_name: ele.Collection,
    solution_name: ele.Solution,
    is_admin: ele.isAdmin === "false" ? false : true,
  };
  try {
    let response = await axiosApi.put(`${putUrl.putUser}${email}`, body);
    toast.dismiss(a);
    toast.success(
      `User "${ele.Firstname}${ele.Lastname}" Updated Successfully!!!`
    );
    setFetch((current) => !current);
    return response;
  } catch (err) {
    console.log("Error in Updating Persona", err);
  }
};

export const deleteUser = async (ele, setFetch) => {
  let a = toast.warning("User Deleting...", { autoClose: false });
  try {
    let response = await axiosApi.delete(`${deleteUrl.deleteUser}${ele.email}`);
    toast.dismiss(a);
    toast.success(`User "${ele.firstname}" Deleted Successfully!!!`);
    setFetch((current) => !current);
  } catch (err) {
    toast.dismiss(a);
    console.log("Error in deleting Persona", err);
  }
};

export const loginentry = async (ele) => {
  let body = {
    email: ele.Username,
    password: ele.Password,
  };

  try {
    let res = await axiosApi.post(`${postUrl.login}`, body);
    toast.success("Logged In Successfully!!!");
    return res;
  } catch (err) {
    toast.error("Authentication Failed!!!");
    console.log("Error in login", err);
  }
};

export const promptGenerate = async (
  userEmail,
  sessionId,
  query,
  tables,
  persona_name
) => {
  try {
    let body = {
      email_id: userEmail,
      session_id: sessionId,
      user_query: query,
      persona_table: tables,
      persona_name: persona_name,
    };

    let res = await axiosApi.post(`${postUrl.prompt}`, body);

    return res?.data;
  } catch (err) {
    toast.error("Unable to Generate, Please try again!!!");
    console.log("Error in generatingPrompt", err);
  }
};

export const audioGenerate = async (prompt) => {
  try {
    let res = await axiosApi.post(`${postUrl.audio}`, {
      insight: prompt,
    });
    return res.data;
  } catch (err) {
    console.log("Error in generating Audio", err);
  }
};

export const deleteSessionid = async (email, sessionPersonaVal, sessionid) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteSession}${email}/${sessionPersonaVal}/${sessionid}/`
    );
    console.log("delete session", res);
    toast.error(`${sessionid} deleted successfully`);
  } catch (err) {
    console.log("Error in deleting session", err);
  }
};

export const deleteDashSessionid = async (
  email,
  sessionid,
  personaValueList
) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteSessionIdinDash}${email}/${sessionid}/${personaValueList}/`
    );
    console.log("delete session", res);
    toast.error(`${sessionid} deleted successfully`);
  } catch (err) {
    console.log("Error in deleting session", err);
  }
};

export const sendEmail = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.sendEmail}`, body);
    console.log("sendEmail", res);
    toast.success("Email sent successfully!!!");
  } catch (err) {
    toast.error("Please Enter the vaild e-mail");
  }
};

export const sendDetailInWhatsApp = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.shareWhatsApp}`, body);
    console.log("sendResponseinWhatsApp", res);
    toast.success("Response sent successfully!!!");
  } catch (err) {
    toast.error("Please Enter the vaild Phone number");
  }
};

export const generateKpi = async (body) => {
  console.log("generateKpi", body);
  try {
    let res = await axiosApi.post(`${postUrl.kpi}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in generate KPI", err);
  }
};

export const deleteAllsessions = async (email, persona) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteAllSession}${email}/${persona}/`
    );
    console.log("Delete all session", res);
    toast.success("Session deleted successfully!!!");
  } catch (err) {
    console.log("Error in Deleting All session", err);
  }
};

export const deleteAllSessionDash = async (email, persona) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteAllSessioninDash}${email}/${persona}/`
    );
    console.log("Delete all session", res);
    toast.success("Session deleted successfully!!!");
  } catch (err) {
    toast.error("Error in Deleting All session", err);
  }
};

export const InitialSetup = async (body) => {
  console.log("Initial", body);
  try {
    let res = await axiosApi.get(
      `${getUrl.initialSetup}${body.bucket_name}/${body.collection_name}`
    );
    let a = res.data;

    return a;
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const uploadDocuments = async (body) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  console.log("uploadDocuments", body);
  try {
    let res = await axiosApi.post(`${postUrl.uploadDocuments}`, body);
    console.log("uploadDocuments", res);
    toast.dismiss(a);
    toast.success("File Uploaded");
    // setUploading((current) => !current);
    return res;
  } catch (err) {
    toast.dismiss(a);
    if (err.response.status === 403) {
      toast.error("Unauthorized. Please check the url");
    } else {
      toast.error("File Uploading Failed");
    }

    console.log("Error in uploadDocuments", err);
  }
};

export const documentSessionHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.documentSessionHistory}`, body);
    let a = res.data;
    console.log(res.data);
    return a;
  } catch (err) {
    console.log("Error in documentSessionHistory", err);
  }
};

export const documentPrompt = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.documentPrompt}`, body);
    let a = res.data;
    console.log(res);
    return a;
  } catch (err) {
    toast.error("Unable to Generate, Please try again !!!");
    console.log("Error in documentPrompt", err);
  }
};

export const documentSessionChatHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.documentChathistory}`, body);
    let a = res.data;
    console.log(res.data);
    return a;
  } catch (err) {
    console.log("Error in documentSessionChatHistory", err);
  }
};

export const deleteDocumentID = async (email, deleteSession, collectioName) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deletedocument}${email}/${deleteSession}/${collectioName}/`
    );
    let a = res.data;
    console.log("deleteDocumentID", res);
    toast.error(`${res.data}`);
    return a;
  } catch (Err) {
    console.log("Error in documentSessionChatHistory", Err);
  }
};

export const deleteDocumentAll = async (body) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteAlldocument}${body.email}/${body.collection_name}`
    );
    let a = res.data;
    console.log(res);
    // toast.error("Sessions deleted successfully!!!");
    return a;
  } catch (Err) {
    console.log("Error in deleteDocumentAll", Err);
  }
};

export const bucketList = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.bucketList}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in bucketList", err);
  }
};

export const createCollections = async (body, setFetch) => {
  try {
    let res = await axiosApi.post(`${postUrl.createCollection}`, body);
    toast.success(`${res.data.message}`);
    setFetch((current) => !current);
    console.log("createCollections", res);
  } catch (err) {
    console.log("Error in createCollections", err);
  }
};

export const deleteCollections = async (body, setFetch) => {
  let a = toast.warning("Deleting Collection...", { autoClose: false });
  let { collection_name, model_type, is_admin } = body;
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteCollection}/${collection_name}/?model_type=${model_type}&is_admin=${is_admin}`
    );
    toast.dismiss(a);
    toast.success(`${res.data.message}`);

    setFetch((current) => !current);
  } catch (err) {
    console.log("Error in deleteCollections", err);
  }
};

export const editCollection = async (body, name) => {
  console.log("Edit collection", body);
  try {
    let res = await axiosApi.put(`${putUrl.editCollection}${name}/`, body);
    toast.success(`${res.data.message}`);
    console.log("editCollection", res);
  } catch (err) {
    console.log("Error in editCollection", err);
  }
};

export const summarizeDashboard = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.dashboardPrompt}`, body);
    return res?.data;
  } catch (err) {
    toast.error("Summarize Dashboard Failed ");
    if (axiosApi.isCancel(err)) {
      console.log("Request canceled:", err.message);
    } else {
      console.log("Error in summarizeDashboard", err);
    }
  }
};

export const localDocuments = async (body) => {
  let a = toast.warning("File Uploading...");
  try {
    let res = await axiosApi.post(`${postUrl.localDocument}`, body);
    toast.dismiss(a);
    toast.success("File Uploaded");
    return res.data;
  } catch (err) {
    toast.dismiss(a);
    toast.error("File Uploading Failed");
    console.log("Error in localDocuments", err);
  }
};

export const dashboardPrompt = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.dashboardPrompt}`, body);
    return res.data;
  } catch (err) {
    toast.error("Unable to Generate, Please try again!!!");
    console.log("Error in dashboardPrompt", err);
  }
};

export const dashboardSessionHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.dashboardSessionHistory}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardSessionHistory", err);
  }
};

export const dashboardSessionChatHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.dashboardChathistory}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardSessionChatHistory", err);
  }
};

export const deleteDashboardID = async (
  email,
  deleteSession,
  dashboardname
) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deletedashboardsession}${email}/${deleteSession}/${dashboardname}/`
    );
    let a = res.data;
    console.log("deleteDashboardID", res);
    toast.error(`${res.data}`);
    return a;
  } catch (Err) {
    console.log("Error in deleteDashboardID", Err);
  }
};

export const deleteDashboardAll = async (email, dashboardname) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteAlldashboardsession}${email}/${dashboardname}/`
    );
    let a = res.data;
    console.log(res);
    toast.error("Sessions deleted successfully!!!");
    return a;
  } catch (Err) {
    console.log("Error in deleteDashboardAll", Err);
  }
};

export const scheduler = async (body) => {
  console.log("Scheduler body", body);
  try {
    let res = await axiosApi.post(`${postUrl.schedule}`, body);
    let a = res.data;
    console.log("Scheduler", res);
    toast.success("Successfully Scheduled!!!");
    return a;
  } catch (Err) {
    console.log("Error in Scheduler", Err);
  }
};

export const personaUser = async (body) => {
  try {
    let res = await axiosApi.get(`${getUrl.personauser}${body}/`);
    let a = res.data;
    return a;
  } catch (Err) {
    console.log("Error in personaUser", Err);
  }
};

export const feedBack = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.feedback}`, body);
    if (res) {
      return res;
    }
  } catch (Err) {
    console.log("Error in feedBack", Err);
  }
};

export const dataAudioHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.data_audio_history}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dataAudioHistory", err);
  }
};

export const documentAudioHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.document_audio_history}`, body);
    console.log("documentAudioHistory", res);
    return res.data;
  } catch (err) {
    console.log("Error in documentAudioHistory", err);
  }
};
export const dashboardAudioHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.dashboard_audio_history}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardAudioHistory", err);
  }
};

export const pubmedSearch = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.pubmedSearch}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in  pubmedSearch", err);
  }
};

export const referencePapers = async (body) => {
  console.log("in referencePapers", body);
  try {
    let res = await axiosApi.post(`${postUrl.referencePapers}`, body);
    console.log("referencePapers", res);
    return res.data;
  } catch (error) {
    if (axiosApi.isAxiosError(error)) {
      if (error?.code === "ERR_NETWORK") {
        toast.error(error.message);
      } else if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.detail);
      } else if (error?.response?.status === 403) {
        toast.error("Unauthorized. Please check the url");
      } else {
        toast.error(error?.message);
      }
    } else {
      toast.error(error?.message);
    }
  }
};

export const decodedPDF = async (path) => {
  try {
    let res = await axiosApi.post(`${postUrl.decodedContent}`, path);
    console.log("decodedPDF", res);
    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
};

//model_swap_logs
export const modalSwapLogs = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.modalSwapLogs}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in documentAudioHistory", err);
  }
};

//get_model_type
export const getModalType = async (body) => {
  try {
    let res = await axiosApi.get(`${getUrl.getModelType}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in documentAudioHistory", err);
  }
};

//upload_doc_pubmed
export const upload_doc_pubmed = async (body) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.upload_doc_pubmed}`, body);
    toast.dismiss(a);
    toast.success("File Uploaded");
    if (res) {
      return res;
    }
  } catch (error) {
    toast.dismiss(a);

    if (axiosApi.isAxiosError(error)) {
      if (error?.code === "ERR_NETWORK") {
        toast.error(error.message);
      } else if (error?.response?.status === 403) {
        toast.error("Unauthorized. Please check the url");
      } else {
        toast.error(error?.message);
      }
    } else {
      toast.error(error?.message);
    }
  }
};

//upload_local_document_pub
export const upload_local_document_pub = async (body) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.upload_local_document_pub}`, body);
    toast.dismiss(a);
    toast.success("File Uploaded");
    if (res) {
      return res;
    }
  } catch (error) {
    toast.dismiss(a);

    if (axiosApi.isAxiosError(error)) {
      if (error?.code === "ERR_NETWORK") {
        toast.error(error.message);
      } else if (error?.response?.status === 403) {
        toast.error("Unauthorized. Please check the url");
      } else {
        toast.error(error?.message);
      }
    } else {
      toast.error(error?.message);
    }
  }
};

//updating_disease_tagged
export const updating_disease_tagged = async (body) => {
  let a = toast.warning("updating disease...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.updating_disease_tagged}`, body);
    toast.dismiss(a);
    toast.success("Disease Updated");
    // return res.data;
    if (res) {
      return res;
    }
  } catch (err) {
    console.log("Error in upload_local_document_pub", err);
  }
};

//get_disease_tagged
export const get_disease_tagged = async (firstName) => {
  try {
    let res = await axiosApi.get(`${getUrl.get_disease_tagged}${firstName}/`);
    return res.data;
  } catch (err) {
    console.log("Error in get_disease_tagged", err);
  }
};

//get_all_disease_tagged
export const get_all_disease_tagged = async (firstName) => {
  try {
    let res = await axiosApi.get(
      `${getUrl.get_all_disease_tagged}/${firstName}/`
    );
    return res.data;
  } catch (err) {
    console.log("Error in get_disease_tagged", err);
  }
};
// Show chart axiosApi
export const getTexttoDashboard = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.textToDashboard}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in chart dispaly", err);
  }
};

// Store the chart response in DB

export const updateChartResponseInHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.addChartResponseInHistory}`, body);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in store response in data base", err);
  }
};

// Show chart axiosApi
export const pubmedSearchSummary = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.pubmedSearchSummary}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in chart dispaly", err);
  }
};

// Audio prompt for data search axiosApi

export const generateAudio = async (audioData) => {
  try {
    const formData = new FormData();
    audioData.forEach((chunk) => {
      formData.append("audio", chunk);
    });

    const response = await axiosApi.post(
      `${postUrl.audioPromptForData}`,
      formData
    );
    if (response) {
      const result = response.data;
      return result;
    } else {
      console.error("Error generating audio:", response.statusText);
      // Handle error
    }
  } catch (error) {
    console.error("Error generating audio:", error);
    // Handle error
  }
};

// Audio prompt for doc search axiosApi

export const generateAudioForDoc = async (audioData) => {
  try {
    const formData = new FormData();
    audioData.forEach((chunk) => {
      formData.append("audio", chunk);
    });

    const response = await axiosApi.post(
      `${postUrl.audioPromptForDoc}`,
      formData
    );
    if (response) {
      const result = response.data;
      return result;
    } else {
      console.error("Error generating audio:", response.statusText);
      // Handle error
    }
  } catch (error) {
    console.error("Error generating audio:", error);
    // Handle error
  }
};

// Audio prompt for dash search axiosApi

export const generateAudioForDash = async (audioData) => {
  try {
    const formData = new FormData();
    audioData.forEach((chunk) => {
      formData.append("audio", chunk);
    });

    const response = await axiosApi.post(
      `${postUrl.audioPromptForDash}`,
      formData
    );
    if (response) {
      const result = response.data;
      return result;
    } else {
      console.error("Error generating audio:", response.statusText);
      // Handle error
    }
  } catch (error) {
    console.error("Error generating audio:", error);
    // Handle error
  }
};

//upload_doc_pubmed
export const new_chat_session = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.new_chat_session}`, body);
    if (res) {
      return res;
    }
  } catch (error) {
    toast.error(error?.message);
  }
};

//uri_endpoint
export const uri_endpoint = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.uri_endpoint}`, body);
    if (res) {
      return res;
    }
  } catch (error) {
    toast.error(error?.message);
  }
};

//uri_endpoint
export const searchGetDataJson = async (body) => {
  let payload = {
    project: body.project,
    workbook: body.workbook,
  };
  try {
    let res = await axiosApi.post(
      `${postUrl.search_get_data_json}?account_id=${body.account_id}/`,
      payload
    );
    if (res && res?.data) {
      return res?.data;
    }
  } catch (error) {
    toast.error(error?.message);
  }
};

//uri_endpoint for dash vista
// export const uri_endpoint_dash = async (body) => {
//   try {
//     let res = await axiosApi.post(`${postUrl.dashvista_uri_endpoint}`, body);
//     if (res) {
//       return res;
//     }
//   } catch (error) {
//     toast.error(error?.message);
//   }
// };

export const tableau_dashboard_summary = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.tableau_dashboard_summary}`, body);
    if (res && res?.data) {
      return res?.data;
    }
  } catch (error) {
    toast.error(error?.message);
  }
};

export const tableauDashboardPrompt = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.tableau_dashboard_chat}`, body);
    return res.data;
  } catch (err) {
    toast.error("Unable to Generate, Please try again!!!");
  }
};

export const tableauChatSpeech = async (audioData) => {
  try {
    const formData = new FormData();
    audioData.forEach((chunk) => {
      formData.append("audio", chunk);
    });

    const response = await axiosApi.post(
      `${postUrl.tableau_dashboard_chat_speech}`,
      formData
    );
    if (response) {
      const result = response.data;
      return result;
    } else {
      console.error("Error generating audio:", response.statusText);
      // Handle error
    }
  } catch (error) {
    console.error("Error generating audio:", error);
    // Handle error
  }
};

export const tableauGetSessions = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.tableauGetSessions}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardSessionHistory", err);
  }
};

export const tableauRenameSession = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.tableauRenameSession}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardSessionHistory", err);
  }
};

export const tableauChatHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.tableauSessionChat}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardSessionChatHistory", err);
  }
};

export const tableauAudioHistory = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.tableauAudioHistory}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in dashboardAudioHistory", err);
  }
};

export const createWorkBookKpi = async (body, setFetch) => {
  let a = toast.warning("WorkBook Creating...", { autoClose: false });
  try {
    let response = await axiosApi.post(`${postUrl.createWorkBook}`, body);

    toast.dismiss(a);
    toast.success("WorkBook Created Successfully!!!");
    setFetch((current) => !current);
    return response;
  } catch (err) {
    toast.dismiss(a);
    if (err.response.status === 500) {
      toast.error("WorkBook Name Already Exists");
    }
  }
};

export const fetchGeneData = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.fetchGeneData}`, body);
    return res.data;
  } catch (err) {
    console.log("fetch_gene_data", err);
  }
};

export const uploadDocGen = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.uploadDocGen}`, body);
    return res.data;
  } catch (err) {
    console.log("fetch_gene_data", err);
  }
};

export const uploadDocumentGt = async (body) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.uploadDocumetGt}`, body);
    toast.dismiss(a);
    toast.success("File Uploaded");
    return res.data;
  } catch (err) {
    toast.dismiss(a);
    toast.error("File Uploading Failed");
    console.log("uploadDocumentGt", err);
  }
};

export const uploadLocalDocumetGt = async (body) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.uploadLocalDocumetGt}`, body);
    toast.dismiss(a);
    toast.success("File Uploaded");
    return res.data;
  } catch (err) {
    toast.dismiss(a);
    toast.error("File Uploading Failed");
    console.log("uploadLocalDocumetGt", err);
  }
};

export const contentTagingSearch = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.contentTaggingSearch}`, body);
    return res;
  } catch (err) {
    console.log("uploadLocalDocumetGt", err);
  }
};

export const contentDataBaseList = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.contentDbList}`, body);
    return res.data;
  } catch (err) {
    console.log("Getting error from data base list", err);
  }
};

export const contentTableList = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.contentTableList}`, body);
    return res.data;
  } catch (err) {
    console.log("Getting error from data table list", err);
  }
};

export const contentTagIntoTable = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.contentTagTable}`, body);
    return res.data;
  } catch (err) {
    console.log("Getting error from data table list", err);
  }
};

//Content Generation
export const generateContentUpload = async (file, userName) => {
  try {
    const formData = new FormData();
    formData.append("template_file", file);

    const response = await axiosApi.post(
      `${postUrl.contentTemplateUpload}?collection_name=${userName}`,
      formData
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error uploading File:", response.statusText);
    }
  } catch (error) {
    console.error("Error uploading File:", error);
  }
};

//content extract the information
export const generateExtractInfo = async (files, userName) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    const formData = new FormData();
    //formData.append("info_doc", files);
    files.forEach((file) => {
      formData.append("info_doc", file); // Append each file with the same key
    });

    const response = await axiosApi.post(
      `${postUrl.contentGenerateInfo}?collection_name=${userName}`,
      formData
    );
    if (response.status === 200) {
      toast.dismiss(a);
      toast.success(response.data);
      return response.data;
    } else {
      toast.dismiss(a);
      toast.error("File uploaded status", response.statusText);
    }
  } catch (error) {
    console.error("Error uploading File:", error);
  }
};

//extract file list
export const generateFileLists = async (userName) => {
  try {
    const response = await axiosApi.post(
      `${postUrl.contentFileLists}?collection_name=${userName}`
    );
    console.log("File List", response);
    return response.data;
  } catch (err) {
    console.error("Error in getting file lists", err);
  }
};

//extract the content with reference
export const generateContentReference = async (body) => {
  try {
    const response = await axiosApi.post(
      `${postUrl.contentGenerateReference}`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Getting content reference error:", error);
  }
};

//raga score
export const generateRagaScore = async (body) => {
  try {
    const response = await axiosApi.post(`${postUrl.contentRagaScore}`, body);
    console.log(response);
    return response.data;
  } catch (err) {
    console.log("Getting error in score value", err);
  }
};

//contentGenerateDocument
export const generateContentDocument = async (body) => {
  let a = toast.warning("Content Generating...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.contentGenerateDocument}`, body);
    toast.dismiss(a);
    toast.success("Content Generated successfully");
    return res.data;
  } catch (err) {
    toast.dismiss(a);
    toast.error("Content Generated Failed");
    //console.log("Getting error from document response", err);
  }
};

//contentGenerateDocument
export const sendTokenApi = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.sendToken}`, body);
    return res;
  } catch (err) {
    toast.error("Token Send Failed");
  }
};

export const dropCollectionList = async (userName) => {
  try {
    let res = await axiosApi.post(
      `${postUrl.dropCollection}?collection_name=${userName}`
    );
    toast.success(res.data.message);
    return res.data;
  } catch (err) {
    toast.error("Collection not removed", err);
  }
};

export const deleteCollectionFileList = async (body) => {
  try {
    let response = await axiosApi.post(
      `${postUrl.deleteCollectionListFile}`,
      body
    );
    toast.success(`File Deleted Successfully!!!`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllCollectionFileList = async (body) => {
  try {
    let response = await axiosApi.post(
      `${postUrl.deleteCollectionAllListFile}`,
      body
    );
    toast.success(`All file collection Deleted Successfully!!!`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

// language translation file upload
export const documentContentUpload = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosApi.post(
      `${postUrl.translateDocumentUpload}`,
      formData
    );

    console.log("File upload", response);
    if (response.status === 200) {
      console.log("Server response:", response.data);
      return response.data;
    } else {
      console.error("Error uploading File:", response.statusText);
    }
  } catch (error) {
    console.error("Error uploading File:", error);
  }
};

//translate the column data based on dropdown
export const translateColumnData = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.translateColumnData}`, body);
    return res;
  } catch (err) {
    console.log("checking transalte", err?.response?.data?.detail);
    toast.error(err?.response?.data?.detail);
  }
};

export const uniqueValueColumnData = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.uniqueValueForColumnData}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in column data", err?.response?.data?.detail);
    toast.error(err?.response?.data?.detail);
  }
};

export const dataListInMedInsight = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.dataResForMedInsight}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in data list", err);
    toast.error(err?.response?.data?.detail);
  }
};

export const columnDataForMedPulse = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.getColumnDataForMedPulse}`, body);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in column data", err);
    toast.error(err?.response?.data?.detail);
  }
};

//mlr upload file
export const mlrContentDocumentUpload = async (files, userName) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    formData.append("email", userName);

    const response = await axiosApi.post(`${postUrl.uploadMlrDoc}`, formData);

    if (response.status === 200) {
      toast.dismiss(a);
      toast.success("Document uploaded successfully");
      return response;
    } else {
      toast.dismiss(a);
      console.error("Error uploading File:", response.statusText);
      toast.error("Error uploading file");
    }
  } catch (error) {
    toast.dismiss(a);
    console.error("Error uploading File:", error.response);
    toast.error("Error uploading file");
  }
};

export const personalIdentityDataList = async (body) => {
  let a = toast.warning("Processing the text...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.personalIdentityData}`, body);

    if (res.status === 200) {
      toast.dismiss(a);
      toast.success(res?.data?.message);
      return res?.data;
    } else {
      toast.dismiss(a);
      console.error("Error getting Data:", res.statusText);
      toast.error("Error getting Data");
    }
  } catch (err) {
    toast.dismiss(a);
    toast.error("Error getting Data", err);
    console.log(err);
  }
};

export const claimsTagging = async (body, file) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    const formData = new FormData();
    formData.append("getdetails", JSON.stringify(body));
    formData.append("file", file);

    const response = await axiosApi.post(`${postUrl.claimsTagging}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      toast.dismiss(a);
      toast.success("File uploaded successfully");
      return response.data;
    } else {
      toast.dismiss(a);
      //console.error("Error uploading File:", response.statusText);
      toast.error("Error uploading file");
    }
  } catch (error) {
    toast.dismiss(a);
    //console.error("Error uploading File:", error.response);
    toast.error("Error uploading file");
  }
};

export const uploadImage = async (body, file) => {
  let a = toast.warning("File Uploading...", { autoClose: false });
  try {
    const formData = new FormData();
    formData.append("getdetails", JSON.stringify(body));
    formData.append("file", file);

    const response = await axiosApi.post(`${postUrl.uploadLogo}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      toast.dismiss(a);
      toast.success("Logo image uploaded successfully");
      return response.data;
    } else {
      toast.dismiss(a);
      //console.error("Error uploading File:", response.statusText);
      toast.error("Error uploading file");
    }
  } catch (error) {
    toast.dismiss(a);
    //console.error("Error uploading File:", error.response);
    toast.error("Error uploading file");
  }
};

// export const waterMarkDetectionList = async () => {
//   try {
//     let response = await axiosApi.post(`${postUrl.waterMarkDetectionData}`);
//     return response.data;
//   } catch (err) {
//     toast.error("Error in getting image list");
//     console.log(err);
//   }
// };

// export const personalIdentitywaterMarkDetectionList = async (body) => {
//   let a = toast.warning("Processing the water mark detection...", {
//     autoClose: false,
//   });
//   try {
//     let response = await axiosApi.post(
//       `${postUrl.personalIdentityWaterMark}`,
//       body
//     );
//     toast.dismiss(a);
//     toast.success("Successfully detect the watermark images");
//     return response.data;
//   } catch (err) {
//     toast.dismiss(a);
//     toast.error(
//       "Sorry! We've encountered an issue with repetitive patterns in your prompt. Please try again with a different prompt."
//     );
//     console.log(err.response);
//     return err.response;
//   }
// };

export const dataDectectivePromptApi = async (body) => {
  let toastMsgId = toast.warning("Data Generating...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.dataDetectivePrompt}`, body);
    toast.dismiss(toastMsgId);
    toast.success("Data Generated Successfully");
    if (res?.data) {
      return res?.data;
    }
  } catch (err) {
    toast.dismiss(toastMsgId);
    toast.error("Unable to Generate, Please try again !!!");
  }
};

export const dataDectectiveRenameApi = async (body) => {
  let toastMsgId = toast.warning("Rename Updating", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.datadecRenamesession}`, body);
    toast.dismiss(toastMsgId);
    toast.success("Data Rename Successfully Successfully");
    if (res) {
      return res;
    }
  } catch (err) {
    toast.dismiss(toastMsgId);
    toast.error("Unable to Generate, Please try again !!!");
  }
};

export const comDectectiveRenameApi = async (body) => {
  let toastMsgId = toast.warning("Rename Updating", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.comDetetectiveRename}`, body);
    toast.dismiss(toastMsgId);
    toast.success("Data Rename Successfully Successfully");
    if (res) {
      return res;
    }
  } catch (err) {
    toast.dismiss(toastMsgId);
    toast.error("Unable to Generate, Please try again !!!");
  }
};

export const getEvaluateApi = async (body, status) => {
  let toastMsgId = toast.warning("Get Evaluate", { autoClose: false });
  try {
    let urlType =
      status === 1
        ? postUrl.getEvaluate
        : status === 2
        ? postUrl.getEvaluateTableau
        : postUrl.getEvaluateDataLens;
    let res = await axiosApi.post(`${urlType}`, body);
    toast.dismiss(toastMsgId);
    toast.success("Data Fetched Successfully");
    if (res) {
      return res?.data;
    }
  } catch (err) {
    toast.dismiss(toastMsgId);
    toast.error("Unable to Generate, Please try again !!!");
  }
};

export const zoomDocumentPrompt = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.zoomRetreivePrompt}`, body);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    toast.error("Unable to Generate, Please try again !!!");
    console.log("Error in documentPrompt", err);
  }
};

export const zoomAudioHistoryApi = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.zoomAudioHistroy}`, body);
    return res.data;
  } catch (err) {
    console.log("Error in documentAudioHistory", err);
  }
};

export const zoomAudioGenerate = async (prompt) => {
  try {
    let res = await axiosApi.post(`${postUrl.zoomAudioConvert}`, {
      insight: prompt,
    });
    return res.data;
  } catch (err) {
    console.log("Error in generating Audio", err);
  }
};

export const zoomdecodedApi = async (path) => {
  try {
    let res = await axiosApi.post(`${postUrl.zoomDecodeContent}`, path);

    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
};

//zoom video convert
export const zoomVideoConvertData = async (body) => {
  let toastMsgId = toast.warning("video uploading...", { autoClose: false });
  const formData = new FormData();
  formData.append("file", body.file);

  try {
    let res = await axiosApi.post(
      `${postUrl.zoomVideoConvert}?collection_name=video_analysis`,
      formData
    );
    toast.dismiss(toastMsgId);
    toast.success("Video Uploaded Successfully");
    return res?.data;
  } catch (err) {
    toast.dismiss(toastMsgId);
    console.log("Error in generating Video", err);
  }
};


export const comDectectivePromptApi = async (body) => {
  let toastMsgId = toast.warning("Data Generating...", { autoClose: false });
  try {
    let res = await axiosApi.post(`${postUrl.comDetectivePrompt}`, body);
    toast.dismiss(toastMsgId);
    toast.success("Data Generated Successfully");
    if (res?.data) {
      return res?.data;
    }
  } catch (err) {
    toast.dismiss(toastMsgId);
    toast.error("Unable to Generate, Please try again !!!");
  }
};

export const getAnswerSalesRepCopilotData = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.getAnswerSalesRepCopilot}`, body);
    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
};

export const editOnePersona = async (params, formValues) => {
  let body = {
    persona_name: formValues.Role,
    solution_name: formValues.SolutionAccess,
    tables: formValues.TableAccess,
    kpi: formValues.kpi,
  }
  try {
    let res = await axiosApi.put(`${putUrl.editPersona}${params}/`, body);
    toast.success(`${res.data.message}`);
    console.log("editCollection", res);
  } catch (err) {
    console.log("Error in editCollection", err);
  }
}

//Med Fusion
export const uploadMedFusionData = async (body) => {
  const formData = new FormData();
  formData.append("file", body.file);
  try {
    let res = await axiosApi.post(
      `${postUrl.uploadMedFusion}/?user_name=${body.name}`,
      formData
    );
    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
};

export const extractedFilesData = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.extractedFiles}`, body);
    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
};

export const downloadDataCallInsights = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.downloadData}`, body);
    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
};

export const queryMedFusionData = async (body) => {
  try {
    let res = await axiosApi.post(`${postUrl.queryMedFusion}`, body);
    return res.data;
  } catch (err) {
    console.log("error in decodedPDF", err);
  }
  };

  export const comDetectiveUploadData = async (body) => {
    const fileSize = body.uploadFile.size;
    if (fileSize) {
      const fileSizeInMB = fileSize / (1024 * 1024);
      const MAX_FILE_SIZE = 25 * 1024 * 1024;
      if (fileSizeInMB <= MAX_FILE_SIZE) {
        const formData = new FormData();
        formData.append("uploadfile", body.uploadFile);
        formData.append("persona_table", body.persona_table.value);
        let a = toast.warning("File Uploading...");
        try {
          let res = await axiosApi.post(
            `${postUrl.comDetectiveUpload}`,
            formData
          );
          toast.dismiss(a);
          toast.success("File Uploaded");
          return res.data;
        } catch (err) {
          if (err.message.includes("400")) {
            toast.error(err.response.data.detail);
          } else {
            toast.error("File Uploading Failed");
          }
          toast.dismiss(a);
          console.log("Error in localDocuments", err);
        }
      } else {
        toast.error("File size exceeds 25MB");
      }
    }
    };